// 使用教程相关模块
import request from '@/utils/request'

// 搜索
export const getTutorialPage = params => {
  return request({
    method: 'GET',
    url: '​​/api/tutorial/page',
    params
  })
}

// 获取教程详情
export const getTutorialDetail = params => {
  return request({
    method: 'GET',
    url: `/api/tutorial/${params}`
  })
}

// 获取教程列表
export const getTutorialOverview = params => {
  return request({
    method: 'GET',
    url: '​​/api/tutorial/overview',
    params
  })
}

// 获取视频教程列表
export const getTutorialVideos = params => {
  return request({
    method: 'GET',
    url: '​​/api/tutorial/videos',
    params
  })
}

// 记录点击视频教程的次数
export const recordVideos = data => {
  return request({
    method: 'POST',
    url: '​​/api/stat/record',
    data
  })
}
