<template>
  <div class="base-container" v-if="accountInfo.length > 0">
    <template v-if="isData">
      <div class="container" v-for="(item, index) in accountInfo" :key="index">
        <div class="">
          <div class="user-res-title" v-if="curKeyWord.name">
            示例{{ curKeyWord.index + 1 }}:{{ curKeyWord.name }}
          </div>
          <!-- item.orderNo -->
          <div
            class="title-tips"
            v-if="item.orderNo && !isFromFriend"
            v-pointerReClick
            @click="openAppeal(item)"
          >
            对查询结果有异议点我?
          </div>
        </div>
        <div class="info-content" v-if="item.status != 4">
          <signet-wrap :country="item.country" />

          <div class="sr-cont-wrap">
            <div class="the-cell-wrap">
              <div class="the-cell-label">Profile ID</div>
              <div class="the-cell-txt">
                {{ item.profileId }}
              </div>
            </div>
          </div>

          <div class="user-list">
            <div class="user-item" v-show="item.searchType !== '1'">
              <div class="label-left">
                <el-popover placement="bottom" trigger="hover">
                  <div>
                    Profile ID/买家Profile页面地址，
                    <span class="cursor" @click="handleJump(3, 9)">
                      点击了解详情
                    </span>
                  </div>
                  <span slot="reference">买家页面</span>
                </el-popover>
              </div>
              <div class="right-txt">
                <!-- item.profileId -->
                {{ item.profileUrl || "" }}
                <a
                  v-if="item.profileDetailUrl"
                  :href="item.profileDetailUrl"
                  target="_blank"
                >
                  <!-- item.searchType === '2' -->
                  <el-button type="text" style="padding: 0">查看详情</el-button>
                </a>
              </div>
            </div>
            <div class="user-item">
              <div class="label-left">
                <el-popover placement="bottom" trigger="hover">
                  <div class="text-align-center">
                  <div class="cursor" @click="handleJump(5, 0)">
                    点击查看具体安全等级说明
                  </div>
                    <div class="mt5">
                      （VINE/TOP买家是否安全，需结合详细数据单独分析）
                    </div>
                  </div>
                  <span slot="reference">账号安全等级</span>
                </el-popover>
              </div>
              <strong
                class="fs16"
                :class="[accountSecurityGradeTextStyle(item)]"
              >
                {{ item.scoreRankingDetail || "" }}
                <el-popover placement="right" trigger="hover">
                  <div class="popover-right red-weight">
                    <div><span>安全</span><i class="svg green-svg"></i></div>
                    <div>
                      <span>比较安全</span><i class="svg yellow-svg"></i>
                    </div>
                    <div>
                      <span>有一定风险</span><i class="svg orange-svg"></i>
                    </div>
                    <div><span>危险</span><i class="svg red-svg"></i></div>
                    <div>
                      <span>极度危险</span><i class="svg an-red-svg"></i>
                    </div>
                    <div>
                      （VINE/TOP买家是否安全，需结合详细数据单独分析）
                    </div>
                  </div>
                  <i
                    slot="reference"
                    style="margin-top: -4px"
                    :class="[
                      'svg',
                      item.scoreRiskGrade == 0 ? 'green-svg' : '',
                      item.scoreRiskGrade == 1 ? 'yellow-svg' : '',
                      item.scoreRiskGrade == 2 ? 'orange-svg' : '',
                      item.scoreRiskGrade == 3 ? 'red-svg' : '',
                      item.scoreRiskGrade == 4 ? 'an-red-svg' : '',
                    ]"
                  ></i>
                </el-popover>
              </strong>
              <span class="identity" v-if="item.status == '3'"
                >(账号身份变卖家)</span
              >
              <span class="identity" v-if="item.status == '4'"
                >(账号页面不存在)</span
              >
            </div>
            <div class="user-item">
              <!-- 账号被打标举报数据 -->
              <div class="label-left">账号关键指标</div>
              <div class="right-txt">
                <div class="item">
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div
                          @click="
                            (e) =>
                              handleTap(
                                item.blacklistDetailFloatingLayer,
                                1,
                                5,
                                e
                              )
                          "
                          v-html="item.blacklistDetailFloatingLayer"
                        ></div>
                        <span slot="reference">黑名单</span>
                      </el-popover>
                    </div>
                    <div class="right-msg">
                      {{ item.blacklistDetail }}
                      <el-popover placement="right" trigger="hover">
                        <div class="popover-right">
                          <div>
                            <span>安全</span><i class="svg green-svg"></i>
                          </div>
                          <div>
                            <span>比较安全</span><i class="svg yellow-svg"></i>
                          </div>
                          <div>
                            <span>有一定风险</span
                            ><i class="svg orange-svg"></i>
                          </div>
                          <div>
                            <span>危险</span><i class="svg red-svg"></i>
                          </div>
                          <div>
                            <span>极度危险</span><i class="svg an-red-svg"></i>
                          </div>
                        </div>
                        <i
                          slot="reference"
                          style="margin-top: -4px"
                          :class="[
                            'svg',
                            item.blacklistRiskGrade == 0 ? 'green-svg' : '',
                            item.blacklistRiskGrade == 1 ? 'yellow-svg' : '',
                            item.blacklistRiskGrade == 2 ? 'orange-svg' : '',
                            item.blacklistRiskGrade == 3 ? 'red-svg' : '',
                            item.blacklistRiskGrade == 4 ? 'an-red-svg' : '',
                          ]"
                        ></i>
                      </el-popover>
                    </div>
                  </div>
                </div>

                <div class="item" style="width: 610px">
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          卖家账号被平台处罚后，这些卖家向平台申诉时交待过的总次数，<span
                            class="cursor"
                            @click="handleJump(5, 3)"
                            >点击了解详情</span
                          >
                        </div>
                        <span slot="reference">至少被平台认定的违规次数</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.sellerComplaintCountFontColor),
                      ]"
                    >
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        :content="item.sellerComplaintCountFloatingLayer"
                      >
                        <span slot="reference">{{
                          item.sellerComplaintCount
                        }}</span>
                      </el-popover>
                      <el-popover placement="right" trigger="hover">
                        <div class="popover-right">
                          <div>
                            <span>安全</span><i class="svg green-svg"></i>
                          </div>
                          <div>
                            <span>比较安全</span><i class="svg yellow-svg"></i>
                          </div>
                          <div>
                            <span>有一定风险</span
                            ><i class="svg orange-svg"></i>
                          </div>
                          <div>
                            <span>危险</span><i class="svg red-svg"></i>
                          </div>
                          <div>
                            <span>极度危险</span><i class="svg an-red-svg"></i>
                          </div>
                        </div>
                        <i
                          slot="reference"
                          style="margin-top: -4px"
                          :class="[
                            'svg',
                            item.sellerComplaintCountRiskGrade == 0
                              ? 'green-svg'
                              : '',
                            item.sellerComplaintCountRiskGrade == 1
                              ? 'yellow-svg'
                              : '',
                            item.sellerComplaintCountRiskGrade == 2
                              ? 'orange-svg'
                              : '',
                            item.sellerComplaintCountRiskGrade == 3
                              ? 'red-svg'
                              : '',
                            item.sellerComplaintCountRiskGrade == 4
                              ? 'an-red-svg'
                              : '',
                          ]"
                        ></i>
                      </el-popover>
                    </div>
                  </div>
                </div>
                <div class="item" style="width: 380px">
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          部分服务商机器养的买家号或者本土用户自己注册的多个买家账号，
                          <span class="cursor" @click="goToArtcle(8)"
                            >点击了解详情</span
                          >
                        </div>
                        <span slot="reference">自养号</span>
                      </el-popover>
                    </div>
                    <div class="right-msg the-right-wrap">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          <span
                            v-if="item.selfSupportAc === '极有可能是自养号'"
                          >
                            该账号可以认定就是自养号，
                            <span class="cursor" @click="goToArtcle(8)">
                              点击了解详情
                            </span>
                          </span>
                          <span v-if="item.selfSupportAc === '可能是自养号'">
                            账号可能是自养号，
                            <span class="cursor" @click="goToArtcle(8)">
                              点击了解详情
                            </span>
                          </span>
                          <span v-if="item.selfSupportAc === '普通账号'">
                            普通账号包括美国注册的买家账号和伪装暂未排查出的自养号，
                            <span class="cursor" @click="goToArtcle(8)">
                              点击了解详情
                            </span>
                          </span>
                        </div>
                        <span slot="reference" class="position-rel">
                          {{ item.selfSupportAc }}
                        </span>
                      </el-popover>
                      <span class="position-rel">
                        <icon-tips-sign
                          :type="item.selfSupportAcRiskGrade"
                          :list="[
                            {
                              lable: '普通账号',
                              icon: 'green-svg',
                            },
                            {
                              lable: '可能是自养号',
                              icon: 'yellow-svg',
                            },
                            {
                              lable: '极有可能是自养号',
                              icon: 'red-svg',
                            },
                          ]"
                        />
                        <icon-new
                          v-if="item.newIdList.includes('newPointId1')"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="item" style="width: 345px">
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          在快现形上，之前被查次数，次数越多风险系数越大，<span
                            class="cursor"
                            @click="handleJump(5, 2)"
                            >点击了解详情</span
                          >
                        </div>
                        <span slot="reference">被查次数</span>
                      </el-popover>
                    </div>
                    <div>
                      <span :class="[fontClass(item, 'accountQueryCount')]">{{
                        convertFieldsForUI(item, "accountQueryCount")
                      }}</span>
                      <el-popover placement="right" trigger="hover">
                        <div class="popover-right red-weight">
                          <div>
                            <span>安全</span><i class="svg green-svg"></i>
                          </div>
                          <div>
                            <span>比较安全</span><i class="svg yellow-svg"></i>
                          </div>
                          <div>
                            <span>有一定风险</span
                            ><i class="svg orange-svg"></i>
                          </div>
                          <div>
                            <span>危险</span><i class="svg red-svg"></i>
                          </div>
                          <div>
                            <span>极度危险</span><i class="svg an-red-svg"></i>
                          </div>
                        </div>
                        <i
                          v-if="item.accountQueryCount !== -1"
                          slot="reference"
                          style="margin-top: -4px"
                          :class="[
                            'svg',
                            item.sellerComplaintCountRiskGrade < 3
                              ? 'green-svg'
                              : '',
                            item.sellerComplaintCountRiskGrade > 2
                              ? 'yellow-svg'
                              : '',
                            item.sellerComplaintCountRiskGrade > 4
                              ? 'orange-svg'
                              : '',
                            item.sellerComplaintCountRiskGrade > 8
                              ? 'red-svg'
                              : '',
                            item.sellerComplaintCountRiskGrade > 12
                              ? 'an-red-svg'
                              : '',
                          ]"
                        ></i>
                      </el-popover>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <div class="left-name" style="width: 120px">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          <span>该买家最少隐藏了多少条评论</span>
                        </div>
                        <span slot="reference"> 最少被隐藏评论数</span>
                      </el-popover>
                    </div>
                    <div class="right-msg the-right-wrap">
                      <span class="position-rel pr5">
                        <span :class="[fontClass(item, 'minReviewHideNum')]">{{
                          convertFieldsForUI(item, "minReviewHideNum")
                        }}</span>
                        <icon-new />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="user-item">
              <div class="label-left">账号基础数据</div>
              <div class="right-txt">
                <div class="item">
                  <div>
                    <div class="left-name">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        content="买家在亚马逊上显示的名字"
                      >
                        <span slot="reference">昵称</span>
                      </el-popover>
                    </div>
                    <div class="right-msg">{{ item.accountName || "" }}</div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        :content="item.halloffameFloatingLayer"
                      >
                        <span slot="reference">名人堂</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.halloffameFontColor),
                      ]"
                    >
                      {{ item.halloffameFlag ? "是" : "否" }}
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          买家Profile页impact数据，如有异常，<span
                            class="cursor"
                            @click="handleJump(5, 6)"
                            >点击了解详情</span
                          >
                        </div>
                        <span slot="reference">impact</span>
                      </el-popover>
                    </div>
                    <div class="right-msg">
                      {{
                        item.totalHelpfulVotes >= 99999
                          ? "99999"
                          : item.totalHelpfulVotes === undefined
                          ? ""
                          : item.totalHelpfulVotes
                      }}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div
                          @click="
                            (e) =>
                              handleTap(
                                item.reviewerRankingDetailFloatingLayer,
                                7,
                                5,
                                e
                              )
                          "
                          v-html="item.reviewerRankingDetailFloatingLayer"
                        ></div>
                        <span slot="reference">账号排名</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.reviewerRankingDetailFontColor),
                      ]"
                    >
                      <span class="position-rel">
                        <strong>{{ item.reviewerRankingDetail || "" }}</strong>
                        <icon-new />
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div
                          @click="
                            (e) =>
                              handleTap(item.vinevoiceFloatingLayer, 4, 5, e)
                          "
                          v-html="item.vinevoiceFloatingLayer"
                        ></div>
                        <span
                          class="f-bold"
                          slot="reference">Vine标</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.vinevoiceFontColor),
                      ]"
                    >
                      {{ item.vinevoiceFlag ? "是" : "否" }}
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <!-- <span>账号注册时长</span> -->
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        content="按照最早的留评时间来参考计算，注册时长大于最早留评时间 "
                      >
                        <span slot="reference">账号最早留评</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.accountMonthsFontColor),
                      ]"
                    >
                      {{
                        item.accountMonths
                          ? `大于${item.accountMonths}月`
                          : "新账号/很少留评的账号"
                      }}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <div class="left-name">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        content="买家Profile页Reviewer ranking在1万以内的为TOP买家"
                      >
                        <span
                          class="f-bold"
                          slot="reference">TOP买家</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.topRankingFontColor),
                      ]"
                    >
                      <!-- 
                      <el-popover
                        v-if="item.topRankingFloatingLayer"
                        placement="bottom"
                        trigger="hover"
                        :content="item.topRankingFloatingLayer"
                      >
                        <span slot="reference">{{
                          item.topRanking || ""
                        }}</span>
                      </el-popover>
                      <span v-else>{{ item.topRanking || "" }}</span> 
                      -->
                      <span class="position-rel">
                        <topRankingLock
                          :row="item"
                          />
                        <icon-new />
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        :content="item.noShareDetailFloatingLayer"
                      >
                        <div
                          @click="
                            (e) =>
                              handleTap(
                                item.noShareDetailFloatingLayer,
                                5,
                                5,
                                e
                              )
                          "
                          v-html="item.noShareDetailFloatingLayer"
                        ></div>
                        <span slot="reference">Profile是否隐藏</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.noShareDetailFontColor),
                      ]"
                    >
                      {{ item.noShareDetail }}
                      <el-popover placement="right" trigger="hover">
                        <div class="popover-right">
                          <div>
                            <span>安全</span><i class="svg green-svg"></i>
                          </div>
                          <div>
                            <span>比较安全</span><i class="svg yellow-svg"></i>
                          </div>
                          <div>
                            <span>有一定风险</span
                            ><i class="svg orange-svg"></i>
                          </div>
                          <div>
                            <span>危险</span><i class="svg red-svg"></i>
                          </div>
                          <div>
                            <span>极度危险</span><i class="svg an-red-svg"></i>
                          </div>
                        </div>
                        <i
                          slot="reference"
                          style="margin-top: -4px"
                          :class="[
                            'svg',
                            item.noShareRiskGrade == 0 ? 'green-svg' : '',
                            item.noShareRiskGrade == 1 ? 'yellow-svg' : '',
                            item.noShareRiskGrade == 2 ? 'orange-svg' : '',
                            item.noShareRiskGrade == 3 ? 'red-svg' : '',
                            item.noShareRiskGrade == 4 ? 'an-red-svg' : '',
                          ]"
                        ></i>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="user-item">
              <div class="label-left">账号深度分析</div>
              <div class="right-txt">
                <div class="item">
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          <span>该买家最近半年最少被删的评论数量 </span>
                        </div>
                        <span slot="reference">半年被删评论数</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.reviewDeletionNumberFontColor),
                      ]"
                    >
                      <span class="position-rel pr5">
                        <span>{{
                          convertFieldsForUI(item, "latest6MonthDeleteReview")
                        }}</span>
                        <icon-new />
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          该买家给过的最少差评数量，如有异常，<span
                            class="cursor"
                            @click="handleJump(5, 9)"
                            >点击了解详情</span
                          >
                        </div>
                        <span slot="reference">给过差评数</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.totalCriticalReviewsFontColor),
                      ]"
                    >
                      {{ item.totalCriticalReviews || 0 }}
                      <el-popover placement="right" trigger="hover">
                        <div class="popover-right">
                          <div>
                            <span>安全</span><i class="svg green-svg"></i>
                          </div>
                          <div>
                            <span>比较安全</span><i class="svg yellow-svg"></i>
                          </div>
                          <div>
                            <span>有一定风险</span
                            ><i class="svg orange-svg"></i>
                          </div>
                          <div>
                            <span>危险</span><i class="svg red-svg"></i>
                          </div>
                          <div>
                            <span>极度危险</span><i class="svg an-red-svg"></i>
                          </div>
                        </div>
                        <i
                          slot="reference"
                          style="margin-top: -4px"
                          :class="[
                            'svg',
                            item.totalCriticalReviewsRiskGrade == 0
                              ? 'green-svg'
                              : '',
                            item.totalCriticalReviewsRiskGrade == 1
                              ? 'yellow-svg'
                              : '',
                            item.totalCriticalReviewsRiskGrade == 2
                              ? 'orange-svg'
                              : '',
                            item.totalCriticalReviewsRiskGrade == 3
                              ? 'red-svg'
                              : '',
                            item.totalCriticalReviewsRiskGrade == 4
                              ? 'an-red-svg'
                              : '',
                          ]"
                        ></i>
                      </el-popover>
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        content="总投票数（helpful votes）/该买家的评论总数(reviews)，能够展示该买家留评是否具有参考价值"
                      >
                        <span slot="reference">均投票数</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.averageHelpfulVotesFontColor),
                      ]"
                    >
                      {{
                        item.averageHelpfulVotesRatio === undefined
                          ? ""
                          : item.averageHelpfulVotesRatio
                      }}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <div class="left-name">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        content="被平台删除的最少评论数，越多风险系数越大"
                      >
                        <span slot="reference">被删评论条数</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.reviewDeletionNumberFontColor),
                      ]"
                    >
                      {{ convertFieldsForUI(item, "reviewDeletionNumber") }}
                      <el-popover placement="right" trigger="hover">
                        <div class="popover-right">
                          <div>
                            <span>安全</span><i class="svg green-svg"></i>
                          </div>
                          <div>
                            <span>比较安全</span><i class="svg yellow-svg"></i>
                          </div>
                          <div>
                            <span>有一定风险</span
                            ><i class="svg orange-svg"></i>
                          </div>
                          <div>
                            <span>危险</span><i class="svg red-svg"></i>
                          </div>
                          <div>
                            <span>极度危险</span><i class="svg an-red-svg"></i>
                          </div>
                        </div>
                        <i
                          slot="reference"
                          style="margin-top: -4px"
                          :class="[
                            'svg',
                            item.reviewDeletionNumberRiskGrade == 0
                              ? 'green-svg'
                              : '',
                            item.reviewDeletionNumberRiskGrade == 1
                              ? 'yellow-svg'
                              : '',
                            item.reviewDeletionNumberRiskGrade == 2
                              ? 'orange-svg'
                              : '',
                            item.reviewDeletionNumberRiskGrade == 3
                              ? 'red-svg'
                              : '',
                            item.reviewDeletionNumberRiskGrade == 4
                              ? 'an-red-svg'
                              : '',
                          ]"
                        ></i>
                      </el-popover>
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          非认证购买的留评数/总留评数，<span
                            class="cursor"
                            @click="handleJump(5, 8)"
                            >点击了解详情</span
                          >
                        </div>
                        <span slot="reference">非认证购买比例</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.verifiedNoPurchaseFontColor),
                      ]"
                    >
                      {{ item.verifiedNoPurchaseReviewPercent || "" }}
                      <el-popover placement="right" trigger="hover">
                        <div class="popover-right">
                          <div>
                            <span>安全</span><i class="svg green-svg"></i>
                          </div>
                          <div>
                            <span>比较安全</span><i class="svg yellow-svg"></i>
                          </div>
                          <div>
                            <span>有一定风险</span
                            ><i class="svg orange-svg"></i>
                          </div>
                          <div>
                            <span>危险</span><i class="svg red-svg"></i>
                          </div>
                          <div>
                            <span>极度危险</span><i class="svg an-red-svg"></i>
                          </div>
                        </div>
                        <i
                          slot="reference"
                          style="margin-top: -4px"
                          :class="[
                            'svg',
                            item.verifiedNoPurchaseRiskGrade == 0
                              ? 'green-svg'
                              : '',
                            item.verifiedNoPurchaseRiskGrade == 1
                              ? 'yellow-svg'
                              : '',
                            item.verifiedNoPurchaseRiskGrade == 2
                              ? 'orange-svg'
                              : '',
                            item.verifiedNoPurchaseRiskGrade == 3
                              ? 'red-svg'
                              : '',
                            item.verifiedNoPurchaseRiskGrade == 4
                              ? 'an-red-svg'
                              : '',
                          ]"
                        ></i>
                      </el-popover>
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover placement="bottom" trigger="hover">
                        <div>
                          经过算法分析，活跃指数越大，账号越优质，<span
                            class="cursor"
                            @click="handleJump(5, 10)"
                            >点击了解详情</span
                          >
                        </div>
                        <span slot="reference">活跃指数</span>
                      </el-popover>
                    </div>
                    <div>
                      {{ item.activeIndex || "" }}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <div class="left-name">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        content="该买家给过差评数大于多少比例的买家"
                      >
                        <span slot="reference">给差评风险评估</span>
                      </el-popover>
                    </div>
                    <div
                      :class="[
                        'right-msg',
                        getFontColor(item.reviewDeletionNumberFontColor),
                      ]"
                    >
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        :content="
                          item.criticalReviewsRankingDetailFloatingLayer
                        "
                      >
                        <span slot="reference">{{
                          item.criticalReviewsRankingDetail || ""
                        }}</span>
                      </el-popover>
                    </div>
                  </div>
                  <div>
                    <div class="left-name">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        content="该买家均投票数大于多少比例的买家"
                      >
                        <span slot="reference">均投票数排名</span>
                      </el-popover>
                    </div>
                    <div class="right-msg">
                      <el-popover
                        placement="bottom"
                        trigger="hover"
                        :content="
                          item.averageHelpfulVotesRankingDetailFloatingLayer
                        "
                      >
                        <span slot="reference">{{
                          item.averageHelpfulVotesRankingDetail || ""
                        }}</span>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="user-item">
              <el-table
                :data="item.tableData"
                stripe
                style="width: 100%"
                :header-cell-style="{ background: '#EEF1F6' }"
                border
                class="table-box"
              >
                <el-table-column prop="reviews" label="留评情况" width="220">
                </el-table-column>
                <el-table-column prop="threeMonth" label="近六个月">
                  <template slot-scope="scope">
                    <el-popover placement="bottom" trigger="hover">
                      <div
                        v-html="getthreeMonth(scope.row.id, item)"
                        @click="
                          (e) =>
                            handleTap(
                              item.latest6monthActualReviewsFloatingLayer,
                              10,
                              4,
                              e
                            )
                        "
                      ></div>
                      <span
                        :class="[
                          scope.row.id == 1
                            ? getFontColor(
                                item.latest6monthActualReviewsFontColor
                              )
                            : '',
                        ]"
                        slot="reference"
                        >{{ scope.row.threeMonth }}</span
                      >
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column prop="oneYear" label="近一年">
                  <template slot-scope="scope">
                    <el-popover placement="bottom" trigger="hover">
                      <div
                        v-html="getoneYear(scope.row.id, item)"
                        @click="
                          (e) =>
                            handleTap(
                              item.latest1yearActualReviewsFloatingLayer,
                              9,
                              4,
                              e
                            )
                        "
                      ></div>
                      <span
                        :class="[
                          scope.row.id == 1
                            ? getFontColor(
                                item.latest1yearActualReviewsFontColor
                              )
                            : '',
                        ]"
                        slot="reference"
                        >{{ scope.row.oneYear }}</span
                      >
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column prop="all" label="全部">
                  <template slot-scope="scope">
                    <el-popover placement="bottom" trigger="hover">
                      <div
                        v-html="getall(scope.row.id, item)"
                        @click="
                          (e) =>
                            handleTap(item.totalReviewFloatingLayer, 11, 4, e)
                        "
                      ></div>
                      <span
                        :class="[
                          scope.row.id == 1
                            ? getFontColor(item.totalReviewFontColor)
                            : '',
                          scope.row.id == 2
                            ? getFontColor(item.totalReviewPercentFontColor)
                            : '',
                        ]"
                        slot="reference"
                        >{{ scope.row.all }}</span
                      >
                    </el-popover>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="user-item uer-star-wrap">
              <div class="statistics-img">
                <div class="statistics-title flex-align-center">
                  <el-popover
                    placement="bottom"
                    trigger="hover"
                    content="快现形统计近一年所有评价的星级分值之和/评价数量"
                  >
                    <span slot="reference" class="pr8">近一年评价平均星级</span>
                  </el-popover>
                  <icon-start
                    :star="item.latest1yearReviewStarDetail.averageStar"
                  />
                  <span class="pl8 position-rel">
                    {{ item.latest1yearReviewStarDetail.averageStar }}分
                    <icon-new v-if="item.newIdList.includes('newPointId1')" />
                  </span>
                </div>
                <div class="statistics">
                  <div>
                    <div class="num">
                      {{
                        item.latest1yearReviewStarDetail
                          ? item.latest1yearReviewStarDetail.star5Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.latest1yearReviewStarDetail
                            ? item.latest1yearReviewStarDetail.star5Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">5星</div>
                  </div>
                  <div>
                    <div class="num">
                      {{
                        item.latest1yearReviewStarDetail
                          ? item.latest1yearReviewStarDetail.star4Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.latest1yearReviewStarDetail
                            ? item.latest1yearReviewStarDetail.star4Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">4星</div>
                  </div>
                  <div>
                    <div class="num">
                      {{
                        item.latest1yearReviewStarDetail
                          ? item.latest1yearReviewStarDetail.star3Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.latest1yearReviewStarDetail
                            ? item.latest1yearReviewStarDetail.star3Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">3星</div>
                  </div>
                  <div>
                    <div class="num">
                      {{
                        item.latest1yearReviewStarDetail
                          ? item.latest1yearReviewStarDetail.star2Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.latest1yearReviewStarDetail
                            ? item.latest1yearReviewStarDetail.star2Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">2星</div>
                  </div>
                  <div>
                    <div class="num">
                      {{
                        item.latest1yearReviewStarDetail
                          ? item.latest1yearReviewStarDetail.star1Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.latest1yearReviewStarDetail
                            ? item.latest1yearReviewStarDetail.star1Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">1星</div>
                  </div>
                  <div class="line"></div>
                </div>
                <div class="statistics-title text-align-center mt15 w400">
                  <el-popover
                    placement="bottom"
                    trigger="hover"
                    content="近一年留评中各星级的占比"
                  >
                    <span slot="reference">近一年留评星级分布</span>
                  </el-popover>
                </div>
              </div>
              <div class="statistics-img">
                <div class="statistics-title flex-align-center">
                  <el-popover
                    placement="bottom"
                    trigger="hover"
                    content="快现形统计所有评价的星级分值之和/评价数量"
                  >
                    <span slot="reference" class="pr8">所有评价平均星级</span>
                  </el-popover>
                  <icon-start :star="item.totalReviewStarDetail.averageStar" />
                  <span class="pl8 position-rel">
                    {{ item.totalReviewStarDetail.averageStar }}分
                    <icon-new v-if="item.newIdList.includes('newPointId1')" />
                  </span>
                </div>
                <div class="statistics">
                  <div>
                    <div class="num">
                      {{
                        item.totalReviewStarDetail
                          ? item.totalReviewStarDetail.star5Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.totalReviewStarDetail
                            ? item.totalReviewStarDetail.star5Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">5星</div>
                  </div>
                  <div>
                    <div class="num">
                      {{
                        item.totalReviewStarDetail
                          ? item.totalReviewStarDetail.star4Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.totalReviewStarDetail
                            ? item.totalReviewStarDetail.star4Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">4星</div>
                  </div>
                  <div>
                    <div class="num">
                      {{
                        item.totalReviewStarDetail
                          ? item.totalReviewStarDetail.star3Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.totalReviewStarDetail
                            ? item.totalReviewStarDetail.star3Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">3星</div>
                  </div>
                  <div>
                    <div class="num">
                      {{
                        item.totalReviewStarDetail
                          ? item.totalReviewStarDetail.star2Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.totalReviewStarDetail
                            ? item.totalReviewStarDetail.star2Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">2星</div>
                  </div>
                  <div>
                    <div class="num">
                      {{
                        item.totalReviewStarDetail
                          ? item.totalReviewStarDetail.star1Percent
                          : "0%"
                      }}
                    </div>
                    <div
                      class="graph"
                      :style="{
                        height: getHeight(
                          item.totalReviewStarDetail
                            ? item.totalReviewStarDetail.star1Percent
                            : ''
                        ),
                      }"
                    ></div>
                    <div class="star">1星</div>
                  </div>
                  <div class="line"></div>
                </div>
                <div class="statistics-title text-align-center mt15 w400">
                  <el-popover
                    placement="bottom"
                    trigger="hover"
                    content="总留评中各星级的占比"
                  >
                    <span slot="reference">总留评星级分布</span>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="container" v-else>
      <div class="null-bg">
        <img src="../../../assets/home/block-name.png" alt="" />
        <span>该账号已被关停！</span>
      </div>
    </div>
    <dialog-appeal :record="curAccount" :visible.sync="visible" />
  </div>
</template>

<script>
import { setItem } from "@/utils/storage.js";
import IconStart from "@/components/IconStart";
import IconTipsSign from "@/components/IconTipsSign";
import DialogAppeal from "@/components/dialog/DialogAppeal.vue";
import SignetWrap from "./signet-wrap.vue";
import topRankingLock from "./topRankingLock.vue";
import { ACCOUNT_SECURITY_GRADE_STYLE } from "@/constants/services/profile";
import { mapState } from "vuex";

export default {
  name: "BaseInfo",
  components: {
    DialogAppeal,
    IconTipsSign,
    IconStart,
    SignetWrap,
    topRankingLock,
  },
  props: {
    curKeyWord: {
      type: Object,
      default: () => {},
    },
    accountInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [],
      isData: true,
      visible: false,
      curAccount: {},
    };
  },
  computed: {
    /**
     * @description 2022/4/14 fuxinyi update 账号安全等级文字颜色定制化
     * https://www.tapd.cn/49550092/prong/stories/view/1149550092001000574
     */
    accountSecurityGradeTextStyle() {
      return ({ scoreRiskGrade: grade }) => {
        return ACCOUNT_SECURITY_GRADE_STYLE[grade];
      };
    },
    /**
     * @description 2022/4/15 fuxinyi update 数字字段转换的模板逻辑抽离 
     * 如果字段没有被返回 则展示对应占位内容, 如果为-1则展示***, 否则原样展示
     * 账号被查次数 accountQueryCount
      最少的被隐藏数 minReviewHideNum
      最近半年被删数 latest6MonthDeleteReview
      留评被删数 reviewDeletionNumber
     */
    convertFieldsForUI() {
      const notExistDataPlaceholderMap = {
        accountQueryCount: "---",
        reviewDeletionNumber: "---",
        minReviewHideNum: "24小时后更新...",
        latest6MonthDeleteReview: "24小时后更新...",
      };
      return (item, type) => {
        if (!Object.prototype.hasOwnProperty.call(item, type)) {
          return notExistDataPlaceholderMap[type];
        }
        if (item[type] === -1) {
          return "***";
        }
        // 之前的前端逻辑 reviewDeletionNumber特殊处理
        if (type === "reviewDeletionNumber") {
          if (item[type] >= 9990) {
            return 9990;
          }
        }
        return item[type];
      };
    },
    /**
     * @description 字体颜色 如果字段返回了或者不为-1就是有值 return变红class
     */
    fontClass() {
      return (item, type) => {
        return !Object.prototype.hasOwnProperty.call(item, type) ||
          item[type] !== -1
          ? "wesent"
          : "";
      };
    },
    ...mapState(["isFromFriend"]),
  },
  watch: {
    accountInfo: {
      handler() {
        if (this.accountInfo.length > 0) {
          this.accountInfo.forEach((item) => {
            item.tableData = [
              {
                reviews: "留评数",
                threeMonth:
                  item.latest6monthActualReviewsDetail === undefined
                    ? ""
                    : item.latest6monthActualReviewsDetail,
                oneYear:
                  item.latest1yearActualReviewsDetail >= 99999
                    ? "99999"
                    : item.latest1yearActualReviewsDetail === undefined
                    ? ""
                    : item.latest1yearActualReviewsDetail,
                all:
                  item.totalReviewDetail >= 99999
                    ? "99999"
                    : item.totalReviewDetail === undefined
                    ? ""
                    : item.totalReviewDetail,
                id: 1,
              },
              {
                reviews: "留评比例",
                threeMonth: item.latest6monthReviewsPercent || "",
                oneYear: item.latest1yearReviewsPercent || "",
                all: item.totalReviewPercent || "",
                id: 2,
              },
              {
                reviews: "留评排名",
                threeMonth: item.latest6monthReviewsRankingDetail || "",
                oneYear: item.latest1yearReviewsRankingDetail || "",
                all: item.totalReviewRankingDetail || "",
                id: 3,
              },
            ];
            if (
              item.reviewerRankingDetailFloatingLayer &&
              item.reviewerRankingDetailFloatingLayer.indexOf(
                "点击了解详情"
              ) !== -1
            ) {
              item.reviewerRankingDetailFloatingLayer =
                item.reviewerRankingDetailFloatingLayer.replace(
                  "点击了解详情",
                  '<span style="cursor: pointer;color: #0000ff;" class="new-html1">点击了解详情</span>'
                );
            }
            if (
              item.latest6monthActualReviewsFloatingLayer &&
              item.latest6monthActualReviewsFloatingLayer.indexOf(
                "点击了解详情"
              ) !== -1
            ) {
              item.latest6monthActualReviewsFloatingLayer =
                item.latest6monthActualReviewsFloatingLayer.replace(
                  "点击了解详情",
                  '<span style="cursor: pointer;color: #0000ff;" class="new-html2">点击了解详情</span>'
                );
            }
            if (
              item.latest1yearActualReviewsFloatingLayer &&
              item.latest1yearActualReviewsFloatingLayer.indexOf(
                "点击了解详情"
              ) !== -1
            ) {
              item.latest1yearActualReviewsFloatingLayer =
                item.latest1yearActualReviewsFloatingLayer.replace(
                  "点击了解详情",
                  '<span style="cursor: pointer;color: #0000ff;" class="new-html3">点击了解详情</span>'
                );
            }
            if (
              item.totalReviewFloatingLayer &&
              item.totalReviewFloatingLayer.indexOf("点击了解详情") !== -1
            ) {
              item.totalReviewFloatingLayer =
                item.totalReviewFloatingLayer.replace(
                  "点击了解详情",
                  '<span style="cursor: pointer;color: #0000ff;" class="new-html4">点击了解详情</span>'
                );
            }
            if (
              item.blacklistDetailFloatingLayer &&
              item.blacklistDetailFloatingLayer.indexOf("点击了解详情") !== -1
            ) {
              item.blacklistDetailFloatingLayer =
                item.blacklistDetailFloatingLayer.replace(
                  "点击了解详情",
                  '<span style="cursor: pointer;color: #0000ff;" class="new-html5">点击了解详情</span>'
                );
            }
            if (
              item.vinevoiceFloatingLayer &&
              item.vinevoiceFloatingLayer.indexOf("点击了解详情") !== -1
            ) {
              item.vinevoiceFloatingLayer = item.vinevoiceFloatingLayer.replace(
                "点击了解详情",
                '<span style="cursor: pointer;color: #0000ff;" class="new-html6">点击了解详情</span>'
              );
            }
            if (
              item.vinevoiceFloatingLayer &&
              item.vinevoiceFloatingLayer.indexOf("点击了解详情") !== -1
            ) {
              item.vinevoiceFloatingLayer = item.vinevoiceFloatingLayer.replace(
                "点击了解详情",
                '<span style="cursor: pointer;color: #0000ff;" class="new-html7">点击了解详情</span>'
              );
            }
            if (
              item.noShareDetailFloatingLayer &&
              item.noShareDetailFloatingLayer.indexOf("点击了解详情") !== -1
            ) {
              item.noShareDetailFloatingLayer =
                item.noShareDetailFloatingLayer.replace(
                  "点击了解详情",
                  '<span style="cursor: pointer;color: #0000ff;" class="new-html8">点击了解详情</span>'
                );
            }
            console.log(item);
            if (
              item.totalReviewPercentFloatingLayer &&
              item.totalReviewPercentFloatingLayer.indexOf("点击了解详情") !==
                -1
            ) {
              item.totalReviewPercentFloatingLayer =
                item.totalReviewPercentFloatingLayer.replace(
                  "点击了解详情",
                  '<span style="cursor: pointer;color: #0000ff;" class="new-html9">点击了解详情</span>'
                );
            }
          });
          this.isData =
            this.accountInfo.filter((item) => {
              return item.status != 4;
            }).length > 0
              ? true
              : false;
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    openAppeal(item) {
      console.log("item", item);
      this.curAccount = item;
      this.visible = true;
    },
    getReviews(item) {
      return item.totalCriticalReviews < 7
        ? item.totalCriticalReviews || 0
        : "≥7";
    },
    getHeight(val) {
      let str = val;
      if (!str) {
        str = 0;
      } else {
        str = str.replace("%", "");
      }
      return str * 0.68 + "px";
    },
    getthreeMonth(id, item) {
      if (id === 1) {
        return item.latest6monthActualReviewsFloatingLayer;
      } else if (id === 2) {
        return item.latest6monthReviewsPercentFloatingLayer;
      } else {
        return item.latest6monthReviewsRankingDetailFloatingLayer;
      }
    },
    getoneYear(id, item) {
      if (id === 1) {
        return item.latest1yearActualReviewsFloatingLayer;
      } else if (id === 2) {
        return item.latest1yearReviewsPercentFloatingLayer;
      } else {
        return item.latest1yearReviewsRankingDetailFloatingLayer;
      }
    },
    getall(id, item) {
      if (id === 1) {
        return item.totalReviewFloatingLayer;
      } else if (id === 2) {
        return item.totalReviewPercentFloatingLayer;
      } else {
        return item.totalReviewRankingDetailFloatingLayer;
      }
    },
    getFontColor(val) {
      let txt;
      switch (val) {
        case "00":
        case undefined:
          txt = "";
          break;
        case "01":
          txt = "weight";
          break;
        case "10":
          txt = "red";
          break;
        case "11":
          txt = "red weight";
          break;
        case "20":
          txt = "green";
          break;
        case "21":
          txt = "green weight";
          break;
        default:
          break;
      }
      return txt;
    },
    handleTap(val, index, id, e) {
      if (val.includes("点击了解详情")) {
        if (
          e.target == document.querySelector(".new-html1") ||
          e.target == document.querySelector(".new-html2") ||
          e.target == document.querySelector(".new-html3") ||
          e.target == document.querySelector(".new-html4") ||
          e.target == document.querySelector(".new-html5") ||
          e.target == document.querySelector(".new-html6") ||
          e.target == document.querySelector(".new-html7") ||
          e.target == document.querySelector(".new-html8") ||
          e.target == document.querySelector(".new-html9")
        ) {
          /* if (id === 4 || id === 5) {
            this.goToCourseDetail(id, index)
            return
          } */
          this.handleJump(id, index);
        }
      }
    },
    formatSelfSupportAcType(selfSupportAc) {
      const config = {
        普通账号: 0,
        极有可能是自养号: 1,
        可能是自养号: 2,
      };
      return config[selfSupportAc] || 0;
    },
    // 知识文章
    goToArtcle(id) {
      const newPage = this.$router.resolve({
        name: "artcleDetail",
        query: { id, type: 1 },
      });
      window.open(newPage.href, "_blank");
      /* this.$router.push({
        path: '/artcle',
        query: {
          id,
          type: 1
        }
      }) */
    },
    goToCourseDetail(courseId, id) {
      const newPage = this.$router.resolve({
        name: "courseDetail",
        query: { id: courseId },
      });
      console.log("xxx", courseId, id);
      window.open(newPage.href, "_blank");
      setItem("askId", id + "");
    },
    handleJump(id, val) {
      setItem("type", val + "");
      window.open(location.origin + `/course?id=${id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.fs16 {
  font-size: 16px;
}

.base-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  .user-res-title {
    font-size: 14px;
    color: #24232f;
    font-weight: bold;
    // margin-bottom: -10px;
  }

  .title-tips {
    cursor: pointer;
    color: #ffa200;
    font-size: 14px;
    text-align: right;
  }

  .container {
    width: 1200px;
    padding: 20px;
    background: #fefefe;
    box-shadow: 0px 2px 12px 0px #edecf6;
    border-radius: 4px;
    margin-bottom: 20px;

    .title {
      position: relative;
      font-size: 14px;
      font-weight: bold;
      color: #24232f;
      margin-bottom: 20px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -20px;
        width: 4px;
        height: 20px;
        background: #4a40ff;
        border-radius: 2px;
      }
    }

    .info-content {
      border: 1px solid #eceff5;
      border-radius: 2px;
      padding: 20px;
      margin-top: 20px;
      position: relative;

      .user-list {
        font-size: 14px;
        color: #24232f;

        .user-item {
          margin-bottom: 16px;

          .label-left {
            font-weight: bold;
          }
        }

        .user-item:nth-child(1),
        .user-item:nth-child(2) {
          display: flex;

          .label-left {
            width: 120px;
          }

          .right-txt {
            font-weight: 400;
          }
        }

        .user-item:nth-child(3) {
          .label-left {
            width: 130px !important;
          }

          .right-txt {
            display: flex;
            flex-wrap: wrap;

            .item {
              display: inline-block;
              margin-top: 10px;

              > div {
                display: flex;
              }

              .left-name {
                width: 120px;
                color: #767d8f;
              }
            }

            .item:nth-child(1) {
              width: 380px;
            }

            .item:nth-child(2) {
              width: 350px;

              .left-name {
                width: 200px;
              }
            }

            .item:nth-child(3) {
              width: 260px;
            }

            .item:nth-child(4) {
              width: 380px;
            }

            .item:nth-child(5) {
              width: 300px;

              .left-name {
                width: 200px;
              }
            }

            .item:nth-child(3) {
              .left-name {
                width: 120px;
              }
            }
          }
        }

        // .user-item:nth-child(3),
        .user-item:nth-child(4),
        .user-item:nth-child(5) {
          .label-left {
            width: 120px;
          }

          .right-txt {
            display: flex;
            margin-top: 8px;

            .item {
              flex: 3.4;

              > div {
                display: flex;
                line-height: 28px;

                .left-name {
                  width: 120px;
                  color: #767d8f;
                }
              }

              &:nth-child(2),
              &:nth-child(3) {
                flex: 3.1;
              }

              &:nth-child(3) {
                flex: 3.5;
              }
            }

            .item:nth-child(2) {
              .left-name {
                width: 120px;
              }
            }
          }
        }

        .user-item:nth-child(6) {
          margin-top: -8px;
        }

        ::v-deep.has-gutter {
          color: #373e4f;
        }

        .table-box {
          ::v-deep.el-table__cell {
            padding: 5px 0;
            font-size: 14px;
          }
        }

        .user-item:nth-child(7),
        .uer-star-wrap {
          display: flex;
          margin-bottom: 0;

          .statistics-img {
            position: relative;
            flex: 1;

            .statistics-title {
              font-weight: bold;
            }

            .statistics {
              position: relative;
              display: flex;
              justify-content: space-around;
              width: 400px;
              height: 120px;
              margin-top: 12px;

              > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;

                .num {
                  font-weight: 400;
                }

                .graph {
                  width: 12px;
                  border-radius: 5px;
                  margin-top: 8px;
                }

                .star {
                  margin-top: 4px;
                  font-weight: 400;
                }
              }

              > div:nth-child(1) {
                .graph {
                  background: linear-gradient(0deg, #2f65ff, #4e84ff);
                }
              }

              > div:nth-child(2) {
                .graph {
                  background: linear-gradient(0deg, #fdb62d, #feed6c);
                }
              }

              > div:nth-child(3) {
                .graph {
                  background: linear-gradient(0deg, #48d9a1, #0fffa2);
                }
              }

              > div:nth-child(4) {
                .graph {
                  background: linear-gradient(0deg, #ff604e, #ffa853);
                }
              }

              > div:nth-child(5) {
                .graph {
                  background: linear-gradient(0deg, #ff455b, #fbb0aa);
                }
              }
            }

            .line {
              position: absolute;
              bottom: 22px;
              left: 0;
              width: 400px;
              height: 1px;
              border-top: 1px dashed #eceff5;
            }
          }
        }
      }
    }

    .info-content:nth-child(1) {
      margin-top: 0;
    }

    .null-bg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border: 1px solid #eceff5;
      border-radius: 2px;

      img {
        width: 193px;
        height: 207px;
      }

      span {
        font-size: 40px;
        font-weight: 500;
        color: #ff2b44;
        line-height: 43px;
        text-shadow: 0px 10px 12px #ffffff;
      }
    }
  }
}

.an-red {
  color: #e50406;
}

.green {
  color: #59e59b;
}

.yellow {
  color: #ffde00;
}

.orange {
  color: #ffa800;
}

.red {
  color: #ff455b;
  font-weight: 700;
}

.red-weight {
  color: #ff455b;
  font-weight: 700;
}

.weight {
  font-weight: 700;
}

.svg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.right-msg {
  display: flex;
  align-items: center;
  flex: 1;
}

.an-red-svg {
  // width: 18px;
  width: 16px;
  height: 16px;
  background: url("../../../assets/home/an-red.svg");
  background-size: 100% 100%;
}

.green-svg {
  width: 16px;
  height: 16px;
  background: url("../../../assets/home/green.svg");
  background-size: 100% 100%;
}

.yellow-svg {
  width: 16px;
  height: 16px;
  background: url("../../../assets/home/yellow.svg");
  background-size: 100% 100%;
}

.orange-svg {
  // width: 18px;
  width: 16px;
  height: 16px;
  background: url("../../../assets/home/orange.svg");
  background-size: 100% 100%;
}

.red-svg {
  // width: 14px;
  width: 16px;
  height: 16px;
  background: url("../../../assets/home/red.svg");
  background-size: 100% 100%;
}

.popover-right {
  div {
    display: flex;
    align-items: center;
    color: #24232f;

    span {
      display: block;
      width: 75px;
      line-height: 20px;
    }

    i {
      display: inline-block;
    }
  }
}

.red-weight {
  span {
    color: red;
    font-weight: 700;
  }
}

.wesent {
  color: red;
  font-weight: 700;
}

.radius {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 8px;
}

.an-red-bg {
  background: #ea0101;
}

.green-bg {
  background: #59e59b;
}

.yellow-bg {
  background: #ffde00;
}

.orange-bg {
  background: #ffa800;
}

.red-bg {
  background: #ff455b;
}

.cursor {
  cursor: pointer;
  color: #0000ff;
}

.color {
  color: #ff455b;
  font-weight: 700 !important;
}

.identity {
  margin-left: 10px;
  color: #ff455b;
  font-weight: 600;
}

.regular {
  color: #59e59b;
}
</style>
