<template>
  <el-dialog
    title="申诉"
    class="get-back-DV"
    width="600px"
    :visible="visible"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      class="get-back-form"
      label-width="80px"
      v-loading="imgLoading"
    >
      <el-form-item label="申诉原因" prop="reasons">
        <el-checkbox-group v-model="form.reasons">
          <el-checkbox
            :label="item"
            name="type"
            v-for="(item, index) in reasonList"
            :key="index"
            >{{ item }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="问题描述" prop="details">
        <el-input
          type="textarea"
          v-model="form.details"
          placeholder="请输入"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="凭证信息" prop="complaintUrls">
        <div class="img-list">
          <template v-if="form.complaintUrls.length > 0">
            <div
              v-for="(item, index) in form.complaintUrls"
              :key="index"
              class="avatar"
            >
              <img :src="item" />
              <i class="el-icon-error" @click="tapDeleteImg(index)"></i>
            </div>
          </template>
          <el-upload
            class="avatar-uploader"
            action="/api/upload/image"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :data="{ type: 'complaint' }"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="format-type">仅支持jpg/png格式，文件大小5M以内</div>
      </el-form-item>
      <el-form-item label="验证码" class="code" prop="captcha">
        <el-input v-model="form.captcha" placeholder="请输入"></el-input>
        <div class="img-code">
          <img
            v-pointerReClick
            :src="imgCode"
            @click="tapCutImg" />
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog" class="cancel-btn">取 消</el-button>
      <el-button @click="onAppeal" class="get-back-bnt">申诉</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { queryComplaintReason, complaint } from '@/api/user.js'
export default {
  name: 'DialogAppeal', // 申诉弹窗
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      form: {
        reasons: [],
        reason: '',
        details: '',
        captcha: '',
        complaintUrls: [],
        orderNo: '',
      },
      rules: {
        reasons: [
          {
            type: 'array',
            required: true,
            message: '至少选择一个原因',
            trigger: 'change'
          }
        ],
        details: [
          { required: true, message: '请填写问题描述', trigger: 'blur' }
        ],
        complaintUrls: [
          {
            type: 'array',
            required: true,
            message: '至少上传一张图片',
            trigger: 'change'
          }
        ],
        captcha: [
          { required: true, message: '请输入图片验证码', trigger: 'blur' }
        ]
      },
      reasonList: [],
      imgCode: '/api/captcha/get?type=bindMobile',
      loading: false,
      loadingText: '申诉信息提交中，请稍后...',
      imgLoading: false,
    }
  },
  watch: {
    record: {
      handler(record) {
        const { orderNo } = record
        this.form.orderNo = orderNo
      },
      deep: true,
    },
    visible: {
      handler(val) {
        if (val) {
          this.queryComplaintReason()
        }
      },
    }
  },
  created() {
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false)
    },
    onAppeal() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.form.reason = this.form.reasons.join(',')
          this.loading = true
          complaint(this.form).then(res => {
            this.loading = false
            if (res.success) {
              this.$message.success('提交申诉成功')
              this.$emit('update-success')
              this.closeDialog()
            } else {
              this.tapCutImg()
            }
          })
        }
      })
    },
    // 获取订单申诉原因列表
    queryComplaintReason() {
      queryComplaintReason().then(res => {
        this.reasonList = res.data
      })
    },
    // 获取图形验证码
    tapCutImg() {
      this.imgCode = '/api/captcha/get?type=bindMobile' + +new Date()
    },
    // 获取图片
    handleAvatarSuccess(res) {
      // console.log(file);
      this.imgLoading = false
      if (res.success) {
        // URL.createObjectURL(file.raw)
        this.form.complaintUrls.push(res.data)
        this.$message.success('图片上传成功')
      }
    },
    // 验证图片信息
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) this.$message.error('上传头像图片只能是JPG和PNG格式!')
      if (!isLt5M) this.$message.error('上传头像图片大小不能超过 5MB!')
      if (isJPG && isLt5M) {
        this.imgLoading = true
      }
      return isJPG && isLt5M
    },
    // 点击删除图片
    tapDeleteImg(index) {
      this.$confirm('您确认要删除该张图片吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message.closeAll()
        this.form.complaintUrls.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    },
  }
}
</script>


<style scoped lang="scss">
/**
  从消耗记录页面 申诉 功能剥离出来的 后续再调整吧
*/
::v-deep.get-back-DV {
  .get-back-form {
    .el-form-item {
      margin-bottom: 18px;
    }
    .el-form-item__error {
      left: 28px;
    }
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      padding: 0 32px 0 30px;
    }
    .el-textarea__inner {
      width: 420px;
      margin-left: 30px;
    }
    .el-input__count {
      background: transparent;
      bottom: -10px;
      right: 45px;
    }
    .code {
      position: relative;
      .el-input__inner {
        width: 348px;
        height: 38px;
        line-height: 38px;
        margin-left: 30px;
      }
      .img-code {
        position: absolute;
        top: 3px;
        right: 30px;
        width: 72px;
        height: 36px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .img-list {
    display: flex;
    padding-left: 30px;
    .avatar {
      position: relative;
      width: 60px;
      height: 60px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
      i {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }
      i.el-icon-error {
        color: #FF455B;
        top: -8px;
        right: -8px;
        font-size: 16px;
      }
    }
  }
  .format-type {
    height: 30px;
    font-size: 14px;
    margin: -20px 0 0 28px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  .el-upload__tip {
    margin: -20px 0 0 25px;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    margin-top: -25px;
    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 46px;
      font-size: 16px;
      color: #444b5b;
    }
    .get-back-bnt {
      color: #fff;
      background: linear-gradient(0deg, #0831ff, #3777ff);
      border-color: #3777ff;
    }
  }
}
</style>