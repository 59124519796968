<template>
  <transition name="fade" mode="out-in">
    <div class="base-container vip-msg-content-box" v-if="isVisible">
      <div class="the-text-box">
        <p class="the-text-title">
          亲，您的积分余额不足，充值后才能查看账号质量查询中**数据。
        </p>
        <p>黑名单查询功能不变，可以一直免费查询哦！</p>
        <p>充值后可恢复完整查询权限</p>
      </div>
      <div>
        <button
          class="k-button k-button--danger is-plain w120"
          style="margin-left: 20px"
          @click="goToRecharge()"
        >
          去充值
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

import { getToday } from "@/utils";
import { setItem } from "@/utils/storage.js";

export default {
  name: "vip-msg-integral", // 会员未到期，无积分
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isVisible: (state) => state.vipIntegralMsg,
    }),
  },
  methods: {
    closeBoxBefore() {
      this.$store.commit("setVipIntegralMsg", false);
      const today = getToday();
      setItem("vipExpireCurDate", today);
    },
    goToRecharge() {
      this.$router.push("/recharge");
    },
    goToActivity() {
      this.$router.push("/activityTiger");
    },
  },
};
</script>

<style scoped lang="scss">
.vip-msg-content-box {
  margin-top: 20px;
  padding: 20px;
  background: #fff0ec;
  box-shadow: 0px 2px 12px 0px #fff0ec;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .the-text-box {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fe8160;
    line-height: 28px;

    .the-text-title {
      font-size: 20px;
      font-weight: bold;
      color: #fe8160;
      padding-bottom: 5px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  overflow: hidden;
  height: 129px;
  opacity: 1;
  // transition: opacity 1.6s ease;
  // transition: height .3s ease;
  transition: all 0.6s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  height: 0;
}
</style>
