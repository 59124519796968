<template>
  <el-popover placement="right" trigger="hover">
    <div class="popover-right">
      <div v-for="(item, index) in uselist" :key="index">
        <span>{{ item.lable }}</span>
        <i class="svg" :class="item.icon"></i>
      </div>
      <!--  <div><span>安全</span><i class="svg green-svg"></i></div>
      <div><span>比较安全</span><i class="svg yellow-svg"></i></div>
      <div><span>有一定风险</span><i class="svg orange-svg"></i></div>
      <div><span>危险</span><i class="svg red-svg"></i></div>
      <div><span>极度危险</span><i class="svg an-red-svg"></i></div> -->
    </div>
    <i
      slot="reference"
      style="margin-top: -4px"
      :class="['svg', tipsClass]"
    ></i>
  </el-popover>
</template>

<script>
export default {
  name: "IconTipsSign", // 警示等级
  props: {
    type: {
      type: [Number, String],
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    uselist() {
      const { list } = this;
      let baseList = [
        {
          lable: "安全",
          icon: "green-svg",
        },
        {
          lable: "比较安全",
          icon: "yellow-svg",
        },
        {
          lable: "有一定风险",
          icon: "orange-svg",
        },
        {
          lable: "危险",
          icon: "red-svg",
        },
        {
          lable: "极度危险",
          icon: "an-red-svg",
        },
      ];
      return list.length ? list : baseList;
    },
    tipsClass() {
      const { type } = this;
      const config = {
        0: "green-svg",
        1: "yellow-svg",
        2: "orange-svg",
        3: "red-svg",
        4: "an-red-svg",
      };
      return config[type] || "";
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.svg {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}
.right-msg {
  display: flex;
  align-items: center;
  flex: 1;
}
.an-red-svg {
  // width: 18px;
  width: 16px;
  height: 16px;
  background: url("~@/assets/home/an-red.svg");
  background-size: 100% 100%;
}
.green-svg {
  width: 16px;
  height: 16px;
  background: url("~@/assets/home/green.svg");
  background-size: 100% 100%;
}
.yellow-svg {
  width: 16px;
  height: 16px;
  background: url("~@/assets/home/yellow.svg");
  background-size: 100% 100%;
}
.orange-svg {
  // width: 18px;
  width: 16px;
  height: 16px;
  background: url("~@/assets/home/orange.svg");
  background-size: 100% 100%;
}
.red-svg {
  // width: 14px;
  width: 16px;
  height: 16px;
  background: url("~@/assets/home/red.svg");
  background-size: 100% 100%;
}
.popover-right {
  div {
    display: flex;
    align-items: center;
    color: #24232f;
    span {
      display: block;
      width: 120px;
      line-height: 20px;
    }
    i {
      display: inline-block;
    }
  }
}
</style>
