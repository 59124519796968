<template>
  <div class="kx-box">
    <!-- <div class="kx-box-cell">
      <div class="kx-box-label">
        举报账号类型
      </div>
      <div class="kx-box-content">
        <el-radio-group v-model="extraParams.type">
          <el-radio
            v-for="el in accountTypeList"
            :key="el.type"
            :label="el.type">
            {{ el.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div> -->
    <div class="kx-box-cell">
      <div class="kx-box-label">
        1、下载模板 
      </div>
      <div class="kx-box-content">
        <ul class="tp-down-list-wrap">
          <li v-for="(el, index) in templateList" :key="index">
            <span
              class="kx-button-text"
              @click="download(el)">
              {{ el.label }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="kx-box-cell">
      <div class="kx-box-label">
        2、选择导入文件
      </div>
      <div class="kx-box-content">
        <el-upload
          class="upload-demo"
          ref="upload"
          :action="uploadUrl"
          :on-success="(event) => uploadSuccess(event)"
          :on-change="(file, fileList) => uploadProgress(file, fileList)"
          :before-upload="(file) => beforeUpload(file)"
          :file-list="fileList"
          :show-file-list="true"
          :limit="3"
          :auto-upload="false">
          <el-button
            size="small"
            icon="el-icon-paperclip">选择文件</el-button>
          <div slot="tip" class="el-upload__tip color-A3 mt10 f14">
            仅支持xls、xlsx格式，文件大小5M以内
          </div>
        </el-upload>
      </div>
    </div>

   <!--  <el-button @click="submit">导入</el-button> -->
  </div>
</template>

<script>
export default {
  name: 'report-batch', // 批量举报
  props: {
    reportDv: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      extraParams: {
        type: '0'
      },
      uploadUrl: '/api/report/importSubmit',
      // uploadType: '0',
      accountTypeList: [
        {
          label: '举报评测买家账号',
          type: '0',
        }, {
          label: '举报评测中介账号',
          type: '1',
        }, {
          label: '举报不诚信评测卖家',
          type: '2',
        },
      ],
      templateList: [
        {
          label: '举报买家账号模板下载',
          type: '0',
          fileUrl: '/excel/举报买家账号上传表格模板.xlsx',
        },
        {
          label: '举报中介服务商模板下载',
          type: '1',
          fileUrl: '/excel/举报中介服务商上传表格模板.xlsx',
        },
        {
          label: '举报卖家模板下载',
          type: '2',
          fileUrl: '/excel/举报卖家账号上传表格模板.xlsx',
        },
      ],
      fileList: [],
    }
  },
  computed: {},
  watch: {
  },
  created() { },
  mounted() {
  },
  methods: {
    download({ type }) {
      const BASEURL = process.env.NODE_ENV === 'development' ? 'https://kuaixianxing.com' : ''
      const fileUrl = `${BASEURL}/api/report/template?type=${type}`
      window.open(fileUrl, '_black')
      /* reportTemplate({ type }).then(res => {
        console.log('res', res)
      }) */
    },
    submit() { // uploadSubmit
      const { fileList } = this
      if (!fileList.length) {
        this.$message.warning('请选择上传文件')
        return
      }
      this.$refs.upload.submit()
    },
    uploadProgress(file, file_list){
      // console.log('file_list', file_list)
      let len = file_list.length
      if (len > 1) {
        this.$set(this, 'fileList', [file_list[len - 1]])
      }else{
        this.$set(this, 'fileList', file_list)
      }
    },
    beforeUpload(file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      const fileTypeConfig = ['xls','xlsx']
      if (!fileTypeConfig.includes(fileType)) {
        this.$message.warning('仅支持xls、xlsx格式')
        return false
      }

      const fileSize = file.size
      const useSize = fileSize / (1024 * 1024)
      if (useSize > 5) {
        this.$message.warning('文件过大，请保存在5M内')
        return false
      }
      return true
    },
    uploadSuccess(res) {
      this.$refs.upload.clearFiles()
      this.$emit('on-success', res)
      /* const { success, message } = res
      if (!success) return this.$message.error(message) */
    },
    /* beforeRemove(file, ) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    }, */
  }, 
}
</script>

<style scoped lang="scss">
.tp-down-list-wrap {
  .kx-button-text {
    padding: 0;
    padding-bottom: 10px;
  }
}
</style>
