<template>
  <div class="footer-box">
    <div class="container">
      <div class="footer-left">
        <div class="contact-us">联系我们</div>
        <div class="footer-column">
          <div class="first">
            客服邮箱：kefu@daitayun.com
          </div>
          <div class="last">
            工作时间：工作日 9:00 - 18:00
          </div>
          <div>
            地址：深圳市龙华区龙华街道玉翠社区华韵路1号金博龙工业厂区厂房B3A06-G
          </div>
        </div>
      </div>
      <div class="footer-right">
        <div class="attention-wx">
          <img src="../../../assets/home/Official Accounts.png" alt="" />
          <span>官方公众号</span>
        </div>
        <div class="attention-wx">
          <img src="../../../assets/home/managaer-qrcode-1.png" alt="" />
          <span>客户经理</span>
        </div>
      </div>
    </div>
    <div class="friend-link">
      <h6>友情链接</h6>
      <ul>
        <li><a href="https://www.lianjiebaobei.com/home" target="_blank">链接宝贝</a></li>
        <li><a href="https://andu365.com/home" target="_blank">暗度</a></li>
      </ul>
    </div>
    <div class="copy-right">
      深圳市带塔云科技有限公司版权所有
      <a href="https://beian.miit.gov.cn/" target="block">粤ICP备2021100807号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footers',
  components: {},
  props: {},
  data() {
    return {

    }
  },
  computed: {},
  watch: {},
  created() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.footer-box {
  position: relative;
  height: 300px;
  padding-top: 40px;
  background-color: #373e4f;
  color: #fff;

  .container {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    padding: 0 40px 0 100px;
  }

  .footer-left {
    .contact-us {
      font-size: 16px;
      margin-bottom: 22px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .footer-column {
      font-size: 16px;

      .first {
        margin-bottom: 22px;
      }

      .last {
        margin-bottom: 22px;
      }
    }
  }

  .footer-right {
    display: flex;
    margin-left: 95px;

    .attention-wx {
      width: 120px;
      margin-right: 60px;
      font-size: 16px;
      text-align: center;

      img {
        display: block;
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }

  .friend-link {

    h6 {
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      color: #fff;
    }

    ul {
      display: flex;
      justify-content: center;
      width: 1200px;
      margin: 0 auto;

      li {

        a {
          color: #fff;
          font-size: 14px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        &+li {
          margin-left: 20px;
        }
      }
    }
  }

  .copy-right {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 12px;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
}
</style>
