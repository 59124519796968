<template>
  <el-carousel :autoplay="false" class="banner-wrap" indicator-position="none" height="180px">
    <!-- <el-carousel-item>
      <a href="https://www.lianjiebaobei.com/home?zbuc=C67401">
        <div class="banner-img-wrap">
          <img style="width: 100%" src="~@/assets/activity/lianjiebaobei.png" />
        </div>
      </a>
    </el-carousel-item> -->
    <!-- <el-carousel-item>
      <a href="https://www.lianjiebaobei.com/home?zbuc=C67401">
        <div class="banner-img-wrap">
          <img style="width: 100%" src="~@/assets/activity/banner1.jpeg" />
        </div>
      </a>
    </el-carousel-item> -->
    <!-- <el-carousel-item>
      <div class="banner-img-wrap" @click="goToActivity(0)">
        <img src="~@/assets/home/banner-tiger-1.png" />
      </div>
    </el-carousel-item> -->
    <el-carousel-item v-for="(el, index) in list" :key="index">
      <div class="banner-img-wrap" @click="onClickItem(el)">
        <img :src="el.uri" />
      </div>
    </el-carousel-item>

    <!-- <el-carousel-item>
      <div class="bg-container" @click="goToArtcle"></div>
    </el-carousel-item>
    <el-carousel-item>
      <div class="bg-num" @click="goToInvite"></div>
    </el-carousel-item>
    <el-carousel-item>
      <div class="bg-num2" @click="goToInvite"></div>
    </el-carousel-item>
    <el-carousel-item>
      <div class="bg-num3" @click="goToCourse"></div>
    </el-carousel-item> -->
  </el-carousel>
</template>

<script>
import { getItem } from "@/utils/storage.js";
import { getBanners } from "@/api/home.js";
export default {
  name: "Bg",
  components: {},
  props: {},
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getBanners();
  },
  mounted() { },
  methods: {
    async getBanners() {
      const { data, success } = await getBanners();
      if (!success) return this.$message.error("获取banner图册失败");
      this.list = data;
    },
    goToActivity(idx) {
      this.$router.push(`/activityTiger?imgIndex=${idx}`);
    },
    onClickItem(el) {
      const { type } = el;
      if (type === 2) {
        this.$router.push('/recharge');
      }
      if (type === 3) {
        window.open('https://h5.erpbool.com/bool?qd=wxh&cy=YM100007', '_blank')
      }

      if (type === 4) {
        window.open('https://www.lianjiebaobei.com/home', '_blank')
      }
      // const config = {
      //   1: "goToCourse",
      //   2: "goToArtcle",
      //   3: "goToInvite",
      // };
      // const fn = config[type] || config[1];
      // this[fn]();
    },
    // 知识文章
    goToArtcle() {
      this.$router.push({
        path: "/artcle",
        query: {
          id: 7,
          type: 1,
        },
      });
    },
    // 邀请有礼
    goToInvite() {
      if (getItem("userInfo")) {
        this.$router.push("/invite-activity");
      } else {
        this.$eventBus.$emit("openLoginDV");
      }
    },
    goToCourse() {
      this.$router.push({
        name: "coursePage",
        params: {
          type: "courseVideo",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.banner-wrap {
  min-width: 1200px;
}

.banner-img-wrap {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    // width: 100%;
    height: 100%;
  }
}

.bg-container {
  width: 100%;
  height: 180px;
  background: url("../../../assets/home/banner1-1.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.bg-num {
  width: 100%;
  height: 180px;
  background: url("../../../assets/home/banner2-2.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.bg-num2 {
  width: 100%;
  height: 180px;
  background: url("../../../assets/home/banner3-1.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.bg-num3 {
  width: 100%;
  height: 180px;
  background: url("../../../assets/home/banner4.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.banner-de-wrap {
  width: 100%;
  background-image: url("../../../assets/home/banner-bg-0.png");
  /*  background-color: #b5d1ff; */
  background-color: linear-gradient(0deg, #90baff, #dceaff);
  position: relative;
  overflow: hidden;
  background-position: center 0;
  background-size: auto 100%;
  background-repeat: no-repeat;

  .banner-de {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    margin-right: 100px;

    p {
      font-family: SourceHanSansCN;
    }

    .the-title {
      display: flex;
      align-items: center;
      color: #ffffff; // #FF1E53;
      font-size: 32px;
      margin-top: 30px;
      font-weight: bold;

      .the-spot {
        margin: 5px 15px 0 15px;
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #f0e812; // #FF1E53;
      }
    }

    .the-strong {
      color: #f0e812; // #2D54F4;
    }

    .the-color1 {
      color: #ffffff;
      // color: #FF9000;
    }

    .the-color2 {
      color: #f0e812; // #3C7CFF;
    }
  }
}
</style>
