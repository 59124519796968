<template>
  <div class="home-container">
    <Header />
    <bg />
    <top-report />
    <search-content />
    <old-data />
    <!-- 视频轮播 -->
    <video-swiper />
    <client />
    <!-- <show-message /> -->
    <Footer />
    <right-icon />
  </div>
</template>

<script>
import Header from '@/components/Header/index.vue'
import Bg from './components/bg.vue'
import TopReport from './components/top-report.vue'
import SearchContent from './components/search-content.vue'
import Client from './components/client.vue'
// import ShowMessage from './components/show-message.vue'
import OldData from './components/old-data.vue'
import Footer from './components/Footer.vue'
import RightIcon from '@/components/right-icon/index.vue'

import VideoSwiper from './components/VideoSwiper.vue'

import { getQueryVariable } from '@/utils'
export default {
  name: 'Home',
  components: {
    VideoSwiper,
    Header,
    Bg,
    TopReport,
    SearchContent,
    Client,
    // ShowMessage,
    OldData,
    Footer,
    RightIcon
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    console.log('dome结构渲染完毕')
  },
  methods: {
  },
  beforeRouteEnter (to, from, next) {
    const scene = getQueryVariable('scene')
    if (scene) {
      window.location.href = `${window.location.origin}/inviteCode.html?scene=${scene}`
      return
    }
    next()
  }
}
</script>

<style scoped lang="scss">
.home-container {
  position: relative;
  width: 100%;
  padding-top: 80px;
}
</style>
