<template>
  <div>
    <div class="search-condition">
      <div class="query flex-jc-space-between">
        <div class="query-condition">
          <div :class="['charge', isCharge == 0 ? 'charge-active' : '']" @click="handleCondition(0)">
            账号质量查询
          </div>
          <div :class="['free', isCharge == 1 ? 'free-active' : '']" @click="handleCondition(1)">
            黑名单查询
          </div>
        </div>
        <div class="query-tips-wrap" v-if="searchType == 1">
          <el-popover placement="left" trigger="hover">
            <div>
              <img src="~@/assets/home/query-tips.png" />
            </div>
            <span slot="reference">
              <i class="el-icon-question"></i>
              查看买家页面对应字段位置
            </span>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="search-container" v-if="!isFromFriend">
      <div class="container">
        <div class="search-box" v-loading="loading">
          <div class="search-input" v-if="isCharge === 1">
            <el-input placeholder="Profile ID/FB账号/TT账号/邮箱/QQ/微信等" v-model="freeParams.account"
              class="input-with-select">
              <el-button slot="append" v-preventReClick class="query-btn" @click="handleQueryBtn" :loading="acting">查询
              </el-button>
            </el-input>
            <span class="report-btn" v-preventReClick @click="handleReport">
              <el-popover placement="bottom" trigger="hover" content="可以举报买家、也可以举报中介和卖家">
                <span slot="reference">举报黑名单</span>
              </el-popover>
            </span>
          </div>
          <div class="charge-input" v-else>
            <span class="position-rel">
              <icon-new style="z-index: 100; top: -16px; right: -12px" />
              <el-select style="width: 100px" v-model="website" @change="handleChange">
                <el-option :label="item.name" :value="item.cnt" v-for="item in station" :key="item.cnt"></el-option>
              </el-select>
            </span>
            <!-- :class="searchType == 1 ? 'width-select' : ''" -->
            <el-select ref="inputSelect" v-model="searchType" @change="resetSearchParmas">
              <el-option :label="item.label" :value="item.value" v-for="item in chargeList" :key="item.value">
              </el-option>
            </el-select>
            <!-- <div class="mmqm" v-if="searchType == 1" @click="revnws">
              Name+Helpful Votes+Reviews + Addr+Reviews Date
              Profile页面查询
            </div> -->
            <div class="Profile-search" v-if="searchType == '0'">
              <el-input v-model.trim="Profile" :placeholder="`请输入${checkLabel}的Profile`" ref="searchProfile"></el-input>
            </div>
            <div class="all-search" v-else-if="searchType == 1">
              <div class="top">
                <el-input v-model="searchParms.username" placeholder="Name（必填）" class="name"></el-input>
                <el-input v-model="searchParms.reviewNumber" placeholder="Reviews（必填）" class="reviews"></el-input>
                <el-input v-model="searchParms.helpfulVoteNumber" placeholder="Helpful Votes（选填）" class="helpful-votes">
                </el-input>
              </div>
              <div class="bottom">
                <el-input v-model="searchParms.location" placeholder="Addr（选填）" class="add"></el-input>
                <div class="block">
                  <el-date-picker v-model="searchParms.latestReviewDate" type="date" placeholder="Reviews Date1(选填）"
                    clearable @change="(val) => handleDate(0, val)">
                  </el-date-picker>
                </div>
                <div class="block view">
                  <el-date-picker v-model="searchParms.before2monthReviewDate" type="date"
                    placeholder="Reviews Date2(选填）" clearable @change="(val) => handleDate(1, val)">
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="import-search" v-else-if="searchType == 2">
              <el-button plain v-preventReClick @click="handleImportFile">
                {{ getInputStatus }}
                <i v-if="importStatus == 3"></i>
                <div class="progress-box" :style="{
                  width: 720 * processNum + 'px',
                  transition: 'all 0.5s linear',
                }" v-if="importStatus != 0 && importStatus != 3"></div>
              </el-button>
              <span class="import-status">{{ getImportStatus }}</span>
            </div>
            <el-button class="query-btn" v-preventReClick @click="handleQueryBtn" :loading="acting"
              v-if="searchType != 2">查询一下</el-button>
          </div>
          <div class="search-tip" v-if="searchType == 1">
            注：Name-昵称；Reviews-总评价条数；Helpful
            Votes-总投票数；Addr.-地址；Review Date1-最新的评论时间；Review
            Date2-3个月前的评论时间
          </div>
          <div class="keyword-search" v-if="isCharge == 0 && searchType == 0">
            <div class="keyword-box">
              <span class="tap-example">点击体验：</span>
              <span class="example-item" v-for="(item, index) in keyWordList"
                :class="curKeyWord.name === item.name ? 'the-active' : ''" :key="item.orderNo">示例{{ index + 1 }}:
                <el-button type="text" v-preventReClick @click="searchSample(item, index)">{{ item.name }}</el-button>
              </span>
            </div>
          </div>
          <div class="keyword-search">
            <div class="keyword-box">
              <span class="tap-example">FAQ热搜：</span>
              <span v-for="(el, index) in FAQList" :key="index" @click="toGoFAQ(el)"
                class="example-item cursor-pointer">
                {{ el.label }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vip-msg-expire-box />
    <vip-msg-integral />
    <accounts-list :params="searchAccountsParams" :visible.sync="accountsVisible" :list="accountsList"
      @update-accounts="(e) => continueSearch(e)" />
    <base-info :curKeyWord="curKeyWord" :accountInfo="accountInfo" v-if="isCharge === 0" />
    <report :reportStatus="reportStatus" :reportList="reportList" :total="total" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange" v-else :show-report-sample="showReportSample" ref="report" />
    <report-dv :reportDv="reportDv" @closeReportDv="closeReportDv" />
    <import-file :country="website" :importDV="importDV" @closeImportDV="closeImportDV" />
    <bind-mobile :bindPhoneDV="bindPhoneDV" @bindPhone="handleBindPhone" />
    <search-dv :queryDv="queryDv" @closeQueryDV="closeQueryDV" :options="options" @querySearch="querySearch" />
    <tip-dv :tipDv="tipDv" @closeTipDv="closeTipDv" />
  </div>
</template>

<script>
import {
  encOrder,
  searchAccounts,
  searchAccountSearch,
  getSearchProfileId,
  searchQuery,
  reportSearch,
  searchSamples,
  queryUserCoin,
  importProcess,
  searchSample,
  countryer,
} from "@/api/home.js";
import { orderGet, queryCoin } from "@/api/user.js";
import { setItem, getItem, removeItem } from "@/utils/storage.js";
import { myMixin } from "@/mixins/mixin.js";
import BaseInfo from "./base-info.vue";
import Report from "./report.vue";
import ReportDv from "./report-dv.vue";
import ImportFile from "./import-file.vue";
import bindMobile from "@/components/bind-mobile/index.vue";
import SearchDv from "./search-dv.vue";
import TipDv from "./tip-dv.vue";
import AccountsList from "./accountsList.vue";
import VipMsgExpireBox from "@/components/vipMsg/vip-msg-expire-box.vue";
import VipMsgIntegral from "@/components/vipMsg/vip-msg-integral.vue";

import { getQueryVariable /* , getToday */ } from "@/utils";
import { mapState } from "vuex";
export default {
  name: "SearchContent",
  components: {
    VipMsgIntegral,
    VipMsgExpireBox,
    AccountsList,
    BaseInfo,
    Report,
    ReportDv,
    ImportFile,
    bindMobile,
    SearchDv,
    TipDv,
  },
  props: {},
  mixins: [myMixin],
  data() {
    return {
      FAQList: [
        {
          courseId: "3",
          id: "9",
          label: "什么是买家Profile?",
        },
        {
          courseId: "3",
          id: "11",
          label: "目前除了美国站、德国站、英国站、加拿大站吗？其他站点什么时候可以查询？",
        },
      ],
      isAgain: false,
      accountsList: [],
      accountsVisible: false,
      searchAccountsParams: {},
      showReportSample: true,
      value1: "",
      keyWordList: [],
      curKeyWord: {},
      isCharge: 0, // 0收费，1免费
      searchParms: {
        username: "",
        helpfulVoteNumber: "",
        reviewNumber: "",
        location: "",
        latestReviewDate: "",
        before2monthReviewDate: "",
      },
      freeParams: {
        account: "",
        pageNo: 1,
        pageSize: 10,
      },
      searchType: "0", // 0：Id查，1：多条件查，2：批量查
      website: "US",
      chargeSelect: [
        {
          label: "Profile(美国站)",
          value: "0",
        },
        {
          label: "Name+Helpful Votes+Reviews + Addr+Reviews Date",
          value: "1",
        },
        {
          label: "批量查询 Profile",
          value: "2",
        },
      ],
      chargeSelect1: [
        {
          label: "Profile(德国站)",
          value: "0",
        },
        {
          label: "Name+Helpful Votes+Reviews + Addr+Reviews Date",
          value: "1",
        },
        {
          label: "批量查询 Profile",
          value: "2",
        },
      ],
      chargeSelect2: [
        {
          label: "Profile(英国站)",
          value: "0",
        },
        {
          label: "Name+Helpful Votes+Reviews + Addr+Reviews Date",
          value: "1",
        },
        {
          label: "批量查询 Profile",
          value: "2",
        },
      ],
      chargeSelect3: [
        {
          label: "Profile(加拿大站)",
          value: "0",
        },
        {
          label: "Name+Helpful Votes+Reviews + Addr+Reviews Date",
          value: "1",
        },
        {
          label: "批量查询 Profile",
          value: "2",
        },
      ],
      station: [],
      Profile: "",
      input: "",
      accountInfo: [],
      reportDv: false,
      reportStatus: 0, // 0：未搜索、1：搜索有内容、2：搜索无内容
      importDV: false,
      bindPhoneDV: false,
      reportList: [],
      total: 0,
      importStatus: 0, // 0：初始化、1：查询中、2：查询完成、3：数据下载
      loading: false,
      queryDv: false,
      options: {},
      processId: null,
      processNum: 0,
      importData: {},
      timeId: null,
      tipDv: false,
      checkLabel: "美国站",
      numd: null,
      acting: false,
      isFromXtqQuery: false,
    };
  },
  computed: {
    ...mapState(["isFromFriend"]),
    siteLabel() {
      const { website } = this;
      const siteLabelConfig = {
        US: "Profile链接查询(美国站)",
        DE: "Profile链接查询(德国站)",
      };
      const siteLabel = siteLabelConfig[website] || "Profile链接查询";
      return siteLabel;
    },
    profileLabel() {
      const { website } = this;
      const profileLabelConfig = {
        US: "Profile页面截图查询(美国站)",
        DE: "Profile页面截图查询(德国站)",
      };
      const profileLabel = profileLabelConfig[website] || "Profile页面截图查询";
      return profileLabel;
    },
    chargeList() {
      const { siteLabel, profileLabel } = this;
      return [
        {
          label: siteLabel,
          value: "0",
        },
        {
          label: profileLabel, // 'Profile页面查询', // "Name+Helpful Votes+Reviews + Addr+Reviews Date",
          value: "1",
        },
        {
          label: "批量查询 Profile",
          value: "2",
        },
      ];
    },
    getInputStatus() {
      if (this.importStatus == 0) {
        return "批量导入+";
      } else if (this.importStatus == 3) {
        return "数据下载";
      } else {
        return "";
      }
    },
    getImportStatus() {
      if (this.importStatus == 1) {
        return `查询中（${this.getProcessNum}）`;
      } else if (this.importStatus == 2) {
        return `查询完成（${this.getProcessNum}）`;
      } else {
        return "";
      }
    },
    getProcessNum() {
      return parseInt(this.processNum * 100) + "%";
    },
  },
  watch: {},
  created() {
    this.$eventBus.$off("onSearch");
    this.$eventBus.$on("onSearch", ({ profileId, country }) => {
      this.Profile = profileId;
      if (country === "US") {
        this.chargeSelect = this.chargeSelect3;
      }
      this.isFromXtqQuery = true;
      this.handleQueryBtn();
      window.history.replaceState({}, "", "/");
    });
    this.countryes();
    if (this.isFromFriend) {
      this.getFriend();
    } else {
      this.getAgainQuery();
      this.searchSamples();
    }
    // this.handleChange()
  },
  mounted() {
    /*
    if (getItem('row')) {
      const row = getItem('row')
      removeItem('row')
      this.handleQueryMethod(row)
    }
    */
    this.$eventBus.$on("handleReport", () => {
      this.handleReport();
    });

    this.$refs.searchProfile && this.$refs.searchProfile.focus();
  },
  destroyed() {
    clearInterval(this.processId);
    clearTimeout(this.timeId);
  },
  methods: {
    dealVipMsg(res) {
      const { success, data } = res;
      /*  // 用户会员到期，请充值！
      if (errorCode === 'user.member.expired') {
        this.$store.commit('setVipExpireMsg', true)
        return
      } */

      if (!success || !data) return;
      const {
        memberExpireDay,
        /* rechargeNoticeFlag, */ freeSearch,
        totalCoin,
      } = data;
      // 查询一下不需要弹窗
      // if (memberExpireDay < 0) {
      //   this.$store.commit("setVipExpireMsg", true);
      //   // return
      // }
      /**
       * @description 2022/6/30 fuxinyi fix bug 会员到期不显示提示横幅问题
       * https://www.tapd.cn/49550092/bugtrace/bugs/view/1149550092001000600
       */
      if (memberExpireDay <= 0) {
        this.$store.commit("setVipExpireMsg", true);
        return;
      }

      // 会员未到期，无积分
      if (memberExpireDay > -1 && !freeSearch && !totalCoin) {
        this.$store.commit("setVipIntegralMsg", true);
      }

      /**
       * @description 2022/4/21 fuxinyi update 查询一下不需要弹窗
       * https://www.tapd.cn/49550092/bugtrace/bugs/view/1149550092001000472
       */
      // 到期前7天内不进行弹窗提示
      // if (rechargeNoticeFlag) return;

      // const today = getToday();
      // if (memberExpireDay < 8) {
      //   const vipDayCurDate = getItem("vipDayCurDate");
      //   if (vipDayCurDate !== today) {
      //     this.$store.commit("setVipDayMsg", true);
      //   }
      // }
    },
    revnws() {
      this.$refs.inputSelect.toggleMenu();
    },
    handleChange(val) {
      this.checkLabel = this.station.find((item) => item.cnt === val).name;
      for (let index = 0; index < this.station.length; index++) {
        if (
          this.station[index] == this.station.find((item) => item.cnt === val)
        ) {
          this.numd = index;
        }
      }
      if (this.numd == 1) {
        this.chargeSelect = this.chargeSelect1;
      } else if (this.numd == 2) {
        this.chargeSelect = this.chargeSelect2;
      } else {
        this.chargeSelect = this.chargeSelect3;
      }
      this.searchSamples();
    },
    countryes() {
      countryer().then((res) => {
        if (res.code == 200) {
          this.station = res.data;
        }
      });
    },
    toGoFAQ(el) {
      // courseId, id
      const { courseId, id } = el;
      const newPage = this.$router.resolve({
        name: "courseDetail",
        query: { id: courseId },
      });
      window.open(newPage.href, "_blank");
      setItem("askId", id + "");
    },
    getFriend() {
      const clientId = getQueryVariable("clientId");
      const token = getQueryVariable("token");
      if (clientId && token) {
        encOrder({
          clientId,
          token,
        }).then((res) => {
          console.log("res", res);
          const { message, data, success } = res;
          if (!success) return this.$message.error(message);

          this.curKeyWord = {};
          this.accountInfo = data;
        });
      }
    },
    async searchSamples() {
      let res = await searchSamples({
        country: this.website,
      });
      if (res.success) {
        this.keyWordList = res.data;
        // 查询初始展示第一个 示例
        if (!this.isAgain) this.searchSample(this.keyWordList[0], 0);
      }
    },
    getAgainQuery() {
      // 这是从 查询记录 重新查询跳转过来的
      const row = getItem("row");
      if (row) {
        this.isAgain = true;
        removeItem("row");
        this.handleQueryMethod(row);
      }
      return row;
    },
    handleDate(index, val) {
      if (index === 0) {
        this.searchParms.latestReviewDate = this.format(val);
      } else {
        this.searchParms.before2monthReviewDate = this.format(val);
      }
    },
    handleQueryMethod(val) {
      this.website = val.country;
      if (val.searchType == 1) {
        this.searchType = "1";
        this.searchParms = val;
      } else {
        this.searchType = "0";
        this.Profile = val.profileId;
      }
      // 这里写的烂透了 事情有点多 后续再调整吧
      if (val.country === "DE") {
        this.chargeSelect = this.chargeSelect1;
      } else if (val.country === "EN") {
        this.chargeSelect = this.chargeSelect2;
      } else {
        // US
        this.chargeSelect = this.chargeSelect3;
      }
      this.handleQueryBtn();
    },
    handleCondition(index) {
      window.scrollTo({
        top: 180, // 滚动距离
        behavior: "smooth",
      });
      this.isCharge = index;
    },
    resetSearchParmas() {
      this.Profile = "";
      this.searchParms = {
        username: "",
        helpfulVoteNumber: "",
        reviewNumber: "",
        location: "",
        latestReviewDate: "",
      };
      this.importData = {};
      this.importStatus = 0;
    },
    async handleQueryBtn() {
      this.curKeyWord = {};
      if (!getItem("userInfo")) {
        this.$eventBus.$emit("openLoginDV");
        return false;
      }
      this.$message.closeAll();
      if (this.isCharge === 0) {
        if (this.searchType == "0") {
          if (this.Profile.length == 0) {
            return this.$message.error("请输入Profile");
          }
        } else if (this.searchType == "1") {
          if (this.searchParms.username.trim().length == 0) {
            this.$message.error("请输入Name");
            return;
          } else if ((this.searchParms.reviewNumber + "").trim().length == 0) {
            this.$message.error("请输入Reviews");
            return;
          }
        }
        let params;
        if (this.searchType == "0") {
          params = {
            profileId: this.Profile,
          };
        } else {
          const {
            username,
            helpfulVoteNumber,
            reviewNumber,
            location,
            latestReviewDate,
            before2monthReviewDate,
          } = this.searchParms;
          params = {
            username,
            helpfulVoteNumber,
            reviewNumber,
          };
          if (location) {
            params.location = location;
          }
          if (latestReviewDate) {
            params.latestReviewDate = latestReviewDate;
          }
          if (before2monthReviewDate) {
            params.before2monthReviewDate = before2monthReviewDate;
          }
        }
        params.country = this.website;
        let result = await queryUserCoin(params);
        if (this.searchType == "1") {
          searchAccounts(params).then((res) => {
            const { success, data } = res;
            if (!success) return; // this.$message.error(message)
            if (!data || data.length === 0) {
              this.$message.error("查询不到相关信息");
              this.$confirm("查询不到相关信息", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => { });
              return;
            }

            if (data.length > 10) {
              // 这里的弹窗是为了沿用老弹窗 应该把它干掉的 赶时间 就先这样 后续在搞掉它
              this.options = { totalRecord: data.length };
              this.queryDv = true;
              return;
            }

            this.searchAccountsParams = params;
            this.accountsList = data;
            this.accountsVisible = true;
          });
          this.dealVipMsg(result);
          return;
        }

        this.dealVipMsg(result);

        if (result.success) {
          /*
          if (result.data.memberExpireDay < 0) {
            return
          }
          */
          const {
            hasBindMobile,
            freeSearch,
            searchNoticeFlag,
            coinNum,
            giftCoinNum,
            giftFreeNum,
            searchPrice,
            searchFreeNum,
            queryNoticeFlag,
          } = result.data;

          if (!hasBindMobile) {
            this.bindPhoneDV = true;
            return false;
          }
          if (freeSearch) {
            this.querySearch();
          } else {
            if (this.searchType == "0") {
              if (!searchNoticeFlag) {
                if (
                  coinNum +
                  giftCoinNum +
                  giftFreeNum +
                  searchFreeNum -
                  searchPrice <
                  0
                ) {
                  this.options = result.data;
                  this.queryDv = true;
                } else {
                  this.querySearch();
                }
              } else {
                result.data.searchType = 0;
                this.options = result.data;
                // this.options.searchType = 0
                this.queryDv = true;
              }
            } else {
              if (!queryNoticeFlag) {
                if (
                  coinNum +
                  giftCoinNum +
                  giftFreeNum +
                  searchFreeNum -
                  searchPrice <
                  0
                ) {
                  this.options = result.data;
                  this.queryDv = true;
                } else {
                  this.querySearch();
                }
              } else {
                result.data.searchType = 1;
                this.options = result.data;
                // this.options.searchType = 1
                this.queryDv = true;
              }
            }
          }
        }
      } else {
        if (this.freeParams.account.trim().length == 0) {
          this.$message.error("请输入ID/FB账号/TT账号/邮箱/QQ/微信");
          return;
        }
        this.freeParams.pageNo = 1;
        this.querySearch();
      }
    },
    querySearch() {
      this.queryDv = false;
      window.scrollTo({
        top: 180, // 滚动距离
        behavior: "smooth",
      });
      if (this.isCharge === 0) {
        if (this.searchType == "0") {
          this.getSearchProfileId();
        } else if (this.searchType == "1") {
          this.searchQuery();
        }
      } else {
        this.reportSearch();
      }
    },
    continueSearch(accounts) {
      searchAccountSearch({
        accounts,
        country: this.website,
      }).then((res) => {
        const { success, data } = res;
        if (success) {
          this.accountInfo = data || [];
        } else {
          this.accountInfo = [];
        }
      });
    },
    handleReport() {
      if (!getItem("userInfo")) {
        this.$eventBus.$emit("openLoginDV");
        return false;
      }
      this.reportDv = true;
    },
    closeReportDv() {
      this.reportDv = false;
    },
    closeImportDV(val) {
      this.importDV = false;
      this.importData = val || {};
      if (val && val.taskId) {
        this.importStatus = 1;
        this.processId = setInterval(async () => {
          let res = await importProcess(val.taskId);
          if (res.success) {
            if (res.data === 1 || res.data === -1) {
              clearInterval(this.processId);
              clearTimeout(this.timeId);
              this.processNum = 1;
              this.importStatus = 2;
              setTimeout(() => {
                this.importStatus = 3;
              }, 1000);
            } else {
              this.processNum = res.data;
            }
          }
        }, 1000);
        this.timeId = setTimeout(() => {
          clearInterval(this.processId);
          this.tipDv = true;
        }, 300000);
      }
    },
    async searchSample(val, index) {
      this.loading = true;
      this.curKeyWord = { ...val, index };
      if (this.isFromXtqQuery) {
        delete this.curKeyWord.name;
        this.isFromXtqQuery = false;
      }
      let res = await searchSample({
        profileId: val.profileId,
        country: this.website,
      });
      this.loading = false;
      if (res.success) {
        this.accountInfo = res.data || [];
      }
    },
    async getSearchProfileId(val) {
      let id = val ? val.profileId : this.Profile;
      if (val) {
        if (!getItem("userInfo")) {
          this.$eventBus.$emit("openLoginDV");
          return false;
        }
        this.Profile = "";
      }
      this.accountInfo = [];
      this.loading = true;
      console.log(id);
      let params = {
        profileId: this.Profile,
        country: this.website,
      };
      this.acting = true; // 只有拉取用户数据时按钮才loading
      let res = await getSearchProfileId(params);
      this.acting = false;
      this.loading = false;
      if (res.success) {
        this.accountInfo = res.data || [];
        this.website = this.accountInfo[0].country;
      } else {
        console.log("res", res);
        this.$alert(
          `暂未查询到相关信息，系统已做记录，请<span class='color-red'>24小时后</span>再次查询！感谢您对快现形的支持。<a target="_blank" href="/course?id=4">查看这类账号是否安全可用说明</a>`,
          "查询提示",
          {
            customClass: "fs-message-box",
            dangerouslyUseHTMLString: true,
            confirmButtonText: "我知道了",
          }
        ).then(() => { });
      }
    },
    async searchQuery() {
      this.accountInfo = [];
      this.loading = true;
      const {
        username,
        helpfulVoteNumber,
        reviewNumber,
        location,
        latestReviewDate,
        before2monthReviewDate,
      } = this.searchParms;
      const params = {
        username,
        helpfulVoteNumber,
        reviewNumber,
      };
      if (location) {
        params.location = location;
      }
      if (latestReviewDate) {
        params.latestReviewDate = latestReviewDate;
      }
      if (before2monthReviewDate) {
        params.before2monthReviewDate = before2monthReviewDate;
      }
      let res = await searchQuery(params);
      this.loading = false;
      if (res.success) {
        if (res.data) {
          this.reportStatus = 1;
          this.accountInfo = res.data;
        } else {
          this.reportStatus = 2;
          this.accountInfo = [];
        }
      }
    },
    async reportSearch() {
      this.showReportSample = false;
      this.reportList = [];
      this.loading = true;
      let res = await reportSearch(this.freeParams);
      this.loading = false;
      if (res.success) {
        this.reportStatus = res.data.data.length > 0 ? 1 : 2;
        this.total = res.data.totalRecord;
        this.reportList = res.data.data;
      }
    },
    async handleImportFile() {
      if (!getItem("userInfo")) {
        this.$eventBus.$emit("openLoginDV");
        return false;
      }
      let res = await queryCoin();
      if (res.success) {
        const { hasBindMobile } = res.data;
        if (!hasBindMobile) {
          this.bindPhoneDV = true;
          return false;
        }
      }
      if (this.importStatus == 0) {
        this.importDV = true;
      } else if (this.importStatus == 3) {
        let res = await orderGet({
          orderNo: this.importData.orderNo,
        });
        if (res.success) {
          window.open(res.data.goodsUrl);
        }
      }
    },
    handleBindPhone() {
      this.bindPhoneDV = false;
    },
    closeQueryDV() {
      this.queryDv = false;
    },
    closeTipDv() {
      this.tipDv = false;
    },
    handleSizeChange(val) {
      this.freeParams.pageSize = val;
      this.reportSearch();
    },
    handleCurrentChange(val) {
      this.freeParams.pageNo = val;
      this.reportSearch();
    },
  },
};
</script>

<style scoped lang="scss">
.search-container {
  display: flex;
  justify-content: center;

  // margin: 30px 0;
  // margin-top: 30px;
  .container {
    width: 1200px;
    background: #fefefe;
    box-shadow: 0px 2px 12px 0px #edecf6;
    border-radius: 4px;

    .search-box {
      padding: 20px 20px;

      .search-input {
        display: flex;
        align-items: center;
        width: 100%;

        ::v-deep.el-input__inner {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          font-size: 13px;
        }

        .input-with-select {
          flex: 1;

          ::v-deep.el-input-group__append {
            border: 0;
            background-color: #fff;

            .query-btn {
              margin-left: -10px;
              color: #fff;
              width: 120px;
              height: 40px;
              background-color: #3c7cff;
              border: 0;
              border-radius: 4px 4px 4px 4px;
            }
          }
        }

        .report-btn {
          display: inline-block;
          width: 120px;
          height: 40px;
          background-color: #ff455c;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          color: #24232f;
          margin-left: 18px;
          cursor: pointer;
          border-radius: 4px;
          color: #fff;
        }
      }

      .charge-input {
        display: flex;
        align-items: top;
        position: relative;

        .el-select {
          width: 220px; // 280px;

          ::v-deep.el-input__inner {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            font-size: 13px;
          }
        }

        ::v-deep.width-select {
          width: 360px;
          height: 80px;

          .el-input {
            height: 100%;
          }

          .el-input__inner {
            height: 100%;
            font-size: 0px;
          }
        }

        .mmqm {
          position: absolute;
          top: 22px;
          left: 130px;
          width: 220px;
          font-size: 14px;
        }

        .query-btn {
          margin-left: 10px;
          color: #fff;
          width: 120px;
          height: 40px;
          background-color: #3c7cff;
          border: 0;
          border-radius: 4px;
        }

        .Profile-search {
          flex: 1;

          ::v-deep.el-input__inner {
            border-radius: 8px;
          }
        }

        .all-search {
          .name {
            ::v-deep.el-input__inner {
              border-top-left-radius: 8px;
            }
          }

          .helpful-votes {
            ::v-deep.el-input__inner {
              border-top-right-radius: 8px;
            }
          }

          .add {
            ::v-deep.el-input__inner {
              border-bottom-left-radius: 8px;
            }
          }

          .view {
            ::v-deep.el-input__inner {
              border-bottom-right-radius: 8px;
            }
          }

          .top,
          .bottom {
            display: flex;
            flex: 1;
          }

          .block {
            width: 200px;

            ::v-deep.el-date-editor {
              width: 200px !important;
              margin-right: 18px;

              .el-input__inner {
                padding: 0 16px;
              }

              .el-icon-date {
                margin-top: 0px;

                &::before {
                  margin-right: -320px;
                }
              }

              .el-range__icon {
                margin-left: -22px;
              }

              .el-range-separator {
                line-height: 22px;
              }

              .el-icon-circle-close {
                margin-right: 10px;
              }
            }
          }

          ::v-deep.el-input__inner {
            border-radius: 0;
            padding: 0 16px;
            width: 200px;
          }
        }

        .import-search {
          position: relative;
          flex: 1;

          .el-button {
            position: relative;
            // width: 620px;
            width: 100%;
            height: 40px;
            font-size: 16px;
            font-weight: 400;
            border-radius: 0;
            color: #3c7cff;

            span {
              display: flex;
              align-items: center;
              height: 22px;
            }

            .progress-box {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0.5px;
              left: 0.5px;
              right: 0.5px;
              bottom: 0.5px;
              // width: 617px;
              height: 37px;
              background: linear-gradient(90deg, #90baff, #ebf3ff);
              opacity: 0.6;
            }

            i {
              display: inline-block;
              width: 20px;
              height: 15px;
              background: url("../../../assets/home/download-icon.png") no-repeat 4px 0;
              background-size: 16px 15px;
              vertical-align: middle;
            }
          }

          .import-status {
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }

      .search-tip {
        margin-top: 12px;
        color: #a3aab7;
        font-size: 14px;
      }

      .keyword-search {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #a3aab7;

        .example-item {
          margin-right: 20px;
          color: #3c7cff;

          &.the-active,
          &.the-active .el-button {
            font-weight: bold;
            font-size: 14px;
            color: #3c7cff;
          }

          span {
            cursor: pointer;
            border-bottom: 1px solid #3c7cff;
          }
        }

        .example-item:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.search-condition {
  display: flex;
  justify-content: center;
  // margin: 30px 0;
  margin-top: 30px;

  .query {
    width: 1200px;
    background: #fefefe;
  }

  .query-tips-wrap {
    font-size: 14px;
    line-height: 40px;
    padding-right: 5px;
    color: #ff9500;
    cursor: pointer;
  }

  .query-condition {
    position: relative;
    cursor: pointer;
    height: 40px;

    >div {
      position: absolute;
      width: 150px;
      height: 40px;
    }

    .charge {
      top: 1px;
      left: 0;
      background: url("../../../assets/home/charge.svg") no-repeat 0 3px;
      background-size: 150px 36px;
      color: #000;
      text-align: center;
      line-height: 40px;
      font-weight: 400;
    }

    .charge-active {
      top: 1px;
      left: 0;
      background: url("../../../assets/home/charge-active.svg") no-repeat;
      background-size: 150px 39px;
      z-index: 1;
      color: #fff;
      text-align: center;
      line-height: 39px;
      font-weight: 400;
    }

    .free {
      top: 1px;
      left: 139px;
      background: url("../../../assets/home/free.svg") no-repeat 0 3px;
      background-size: 150px 36px;
      color: #000;
      text-align: center;
      line-height: 40px;
      font-weight: 400;
    }

    .free-active {
      top: 1px;
      left: 139px;
      background: url("../../../assets/home/free-active.svg") no-repeat;
      background-size: 150px 39px;
      z-index: 1;
      color: #fff;
      text-align: center;
      line-height: 39px;
      font-weight: 400;
    }
  }
}

::v-deep.el-select {
  margin-right: 10px;
}

::v-deep.el-select-dropdown__item.selected {
  color: #3c7cff;
  font-weight: 700;
}

::v-deep.el-dropdown-menu__item:hover {
  color: #3c7cff;
}
</style>
