<template>
  <el-dialog
    title="批量导入"
    :visible.sync="importDV"
    width="600px"
    :close-on-click-modal="false"
    :before-close="closeImportDV"
    class="import-DV"
  >
    <div class="import-content" v-if="importDV">
      <div class="item">
        <div class="left-label">1、下载模板</div>
        <div class="right-btn" @click="handleImportTemplate">模板下载</div>
      </div>
        <div class="item">
          <div class="left-label">2、选择导入文件</div>
          <div class="right-btn" v-loading="loading">
            <el-upload
              class="upload-demo"
              ref="upload"
              action="/api/search/importQuery"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :limit="1"
              :data="uploadParams"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
              <!-- :file-list="fileList" -->
              <!-- :auto-upload="false" -->
              <el-button plain slot="trigger"><i></i>选择文件</el-button>
              <div slot="tip" class="el-upload__tip">
                仅支持xls、xlsx格式，文件大小5M以内
              </div>
            </el-upload>
          </div>
        </div>
        <template v-if="!importData.isFree && Object.keys(importData).length > 0">
        <div class="item search">
          3、本次查询将消耗<span>{{ importData.totalPrice }}积分</span
          >，查询结果为空不消耗积分；
        </div>
        <div class="item search">
          4、相同的查询条件<span>72小时</span>内重查不消耗积分；
        </div>
        <div class="item search">5、查询记录入口：[我的帐户]-[查询记录]。</div>
        <div class="item search">6、消耗记录入口：[我的帐户]-[消耗记录]。</div>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeImportDV">取消</el-button>
      <el-button class="affirm-btn" @click="handleSubmit">导入</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { searchTrigger } from '@/api/user.js'
export default {
  name: 'importFile',
  components: {},
  props: {
    importDV: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    country: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      fileList: [],
      loading: false,
      importData: {},
      uploadParams: {
        country: '',
      },
    }
  },
  computed: {},
  watch: {},
  created() {

  },
  destroyed() {
    
  },
  methods: {
    closeImportDV() {
      this.fileList = []
      this.importData = []
      this.$emit('closeImportDV')
    },
    async handleSubmit() {
      if (this.fileList.length === 0) {
        this.$message.closeAll()
        this.$message.error('请先选择文件')
        return
      }
      let res = await searchTrigger(this.importData.taskId)
      if (res.success) {
        this.fileList = []
        this.$emit('closeImportDV', this.importData)
        this.importData = []
      }
      // this.$refs.upload.submit();
    },
    handleChange(file) {
      this.fileList.push(file)
    },
    handleRemove() {
      this.fileList.pop()
    },
    beforeAvatarUpload(file) {
      this.$set(this.uploadParams, 'country', this.country)
      const isXLS = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
      const isLt5M = (file.size / 1024 / 1024) < 5
      if (!isXLS) this.$message.error('上传文件只能是xls和xlsx格式!')
      if (!isLt5M) this.$message.error('上传文件大小不能超过 5MB!')
      if (isXLS && isLt5M) {
        this.loading = true
      }
      return isXLS && isLt5M
    },
    handleAvatarSuccess(res) {
      this.loading = false
      if (res.success) {
        this.$message.success('上传成功')
        this.importData = res.data
      } else {
        this.$message.error(res.message)
      }
    },
    handleImportTemplate() {
      if (process.env.NODE_ENV === 'production') {
        let str = location.href
        window.open(str.replace(location.pathname, '') + '/api/search/importTemplate')
      } else {
        window.open(process.env.VUE_APP_BASEURL + '/api/search/importTemplate')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.import-DV {
  ::v-deep.el-dialog__header {
    border-bottom: 1px solid #e5e4ee;
  }
  .import-content {
    .item {
      display: flex;
      .left-label {
        width: 160px;
        margin-bottom: 18px;
      }
      &:nth-child(1) {
        .right-btn {
          cursor: pointer;
          color: #3c7cff;
        }
      }
      .select-file,
      .upload-demo {
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
        .el-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 32px;
          font-size: 14px;
          font-weight: 400;
          color: #24232f;
          i {
            display: inline-block;
            width: 13px;
            height: 14px;
            vertical-align: middle;
            background: url('../../../assets/home/import-icon.png') no-repeat;
            background-size: 13px 14px;
            margin-right: 4px;
          }
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: #a3aab7;
          margin-top: 8px;
        }
      }
    }
    .search {
      line-height: 32px;
      span {
        color: #ff455b;
      }
    }
    .el-icon-close-tip {
      display: none !important;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 36px;
      border-radius: 18px;
    }
    .affirm-btn {
      background-color: #3c7cff;
      color: #fff;
    }
  }
}
</style>
