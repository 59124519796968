<template>
  <div class="container card-wrap kx-container">
    <div class="sample-prompt-wrap ">
      <div class="the-title">免费查询，让骗子、问题中介、不诚信卖家无所遁形，欢迎举报 </div>
      <div class="">
        1、黑名单查询长期免费，可以通过账号各种信息查询<br/>
        2、欢迎举报骗子、问题中介、不诚信卖家；
      </div>
    </div>
    <div class="sample-wrap kx-descriptions">
      <i class="icon-sample"></i>
      <div class="kx-descriptions-item">
        <div class="kx-descriptions-label">举报时间</div>
        <div class="kx-descriptions-content">2021-10-16 10:41:42</div>
      </div>
      <div class="kx-descriptions-item">
        <div class="kx-descriptions-label">举报账号类型</div>
        <div class="kx-descriptions-content">其他</div>
      </div>
      <div class="kx-descriptions-item">
        <div class="kx-descriptions-label">举报理由</div>
        <div class="kx-descriptions-content"></div>
      </div>
      <div class="kx-descriptions-item">
        <div class="kx-descriptions-label">举报账号信息</div>
        <div class="kx-descriptions-content">微信号：zrz1601376089</div>
      </div>
      <div class="kx-descriptions-item">
        <div class="kx-descriptions-label">举报详情</div>
        <div class="kx-descriptions-content">曝光原因和证据：下完单，返完款后，退货退款，发消息也不回复，百来块的东西，建议拿个碗蹲路边赚的还多</div>
      </div>
      <div class="kx-descriptions-item">
        <div class="kx-descriptions-label">举报图片</div>
        <div class="kx-descriptions-content"></div>
      </div>
      <div class="f14 mt10 color-A3">
        此举报为举报人独立观点，不代表快现形立场。如发现有侵权或者恶意举报，请联系我们。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportSample',  // 举报样例
  components: {},
  props: {
  },
  computed: {},
  watch: {},
  created() {
  },
}
</script>

<style scoped lang="scss">
  .card-wrap {
    padding: 30px;
    background: #FEFEFE;
    box-shadow: 0px 2px 12px 0px #EDECF6;
    border-radius: 4px;
  }
  .sample-prompt-wrap {
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FE8160;
    line-height: 32px;
    background: #FFF0EC;
    .the-title {
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: 600;
      line-height: 36px;
    }
  }
  .sample-wrap.kx-descriptions{
    position: relative;
    border: 1px solid #EEF1F6;
    padding: 30px;
    .icon-sample {
      position: absolute;
      top: 0;
      left: 0;
    }
    .kx-descriptions-item {
      display: flex;
      .kx-descriptions-label {
        width: 120px;
        font-weight: 400;
        color: #a3aab7;
        line-height: 43px;
        margin-right: 35px;
      }
      .kx-descriptions-content {
        font-weight: 400;
        color: #24232f;
        line-height: 43px;
      }
    }
  }
</style>
