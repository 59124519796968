// 账号安全等级文字样式
const ACCOUNT_SECURITY_GRADE_STYLE = Object.freeze({
    '0': '',
    '1': '',
    '2': 'orange',
    '3': 'red',
    '4': 'red'
})
export {
    ACCOUNT_SECURITY_GRADE_STYLE
}