<template>
  <div class="client-container">
    <div class="container">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="title">{{ item.title }}</div>
        <div class="txt">
          {{ item.txt }}
        </div>
        <!-- <div class="footer-img"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Client',
  components: {},
  props: {},
  data() {
    return {
      list: [
        {
          id: 1,
          title: '让骗子无所遁形',
          txt: '举报打标体系收录了亚马逊行业问题账号大数据，让恶意打假、恶意退款、恶意敲诈、买家骗子、中介骗子、职业差评师无所遁形。'
        },
        {
          id: 2,
          title: '免费查询',
          txt: '活动期间可免费查询，帮你快速甄别出你的买家问题账号，从此放心测评。举报打标系统一直免费，全力服务卖家。'
        },
        {
          id: 3,
          title: '美国技术',
          // txt: '技术与分享核心团队来自美国西雅图，有7年以上亚马逊买家数据分析经验；能够实时分析亚马逊买家账号的各种风险。'
          txt: '技术分析核心团队来自美国西雅图，有7年以上亚马逊买家数据分析经验；能够实时分析亚马逊买家账号的各种风险。'
        },
        {
          id: 4,
          title: '全量数据',
          txt: '亚马逊买家账号分析集大成者，帮助卖家精准分析亚马逊买家账号安全，让运营从此变得简单！'
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.client-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  .container {
    display: flex;
    width: 1220px;
    .item {
      position: relative;
      
      height: 363px;
      background: #fefefe;
      width: 24%;
      margin-right: 20px;
      padding: 35px 30px 0;
      box-shadow: 0px 2px 12px 0px #EDECF6;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #24232f;
        margin-bottom: 24px;
        text-align: center;
      }
      .txt {
        font-size: 16px;
        font-weight: 400;
        color: #767d8f;
        line-height: 30px;
      }
      .footer-img {
        position: absolute;
        bottom: 25px;
        right: 20px;
      }
    }
    .item:nth-child(1) {
      background: url('../../../assets/home/clientes1.png');
      background-size: 100% 100%;
      // .footer-img {
      //   width: 112px;
      //   height: 80px;
      //   background: url('../../../assets/home/client-1-son.png');
      //   background-size: 100% 100%;
      // }
    }
    .item:nth-child(2) {
      background: url('../../../assets/home/clientes2.png');
      background-size: 100% 100%;
      .footer-img {
        width: 114px;
        height: 80px;
        background: url('../../../assets/home/client-2-son.png');
        background-size: 100% 100%;
      }
    }
    .item:nth-child(3) {
      background: url('../../../assets/home/clientes3.png');
      background-size: 100% 100%;
      .footer-img {
        width: 120px;
        height: 80px;
        background: url('../../../assets/home/client-3-son.png');
        background-size: 100% 100%;
      }
    }
    .item:nth-child(4) {
      background: url('../../../assets/home/clientes4.png');
      background-size: 100% 100%;
      margin-right: 0px;
      .footer-img {
        width: 143px;
        height: 80px;
        background: url('../../../assets/home/client-4-son.png');
        background-size: 100% 100%;
      }
    }
  }
}
</style>
