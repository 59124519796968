<template>
  <el-dialog
    title="温馨提示"
    :visible.sync="tipDv"
    width="600px"
    :before-close="closeTipDv"
    :close-on-click-modal="false"
    class="tip-dv"
  >
    <div class="tip-content">
      <div class="txt">
        <i class="el-icon-warning"></i>
        当前导入数据过多，预计还需等待<span>50分钟</span>，请耐心等待
      </div>
      <div class="txt">如您还有其他事情处理，可关闭页面，稍后到 [我的帐户-<span>查询记录</span>] 查看结果</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeTipDv">取 消</el-button>
      <el-button class="confirm-btn" @click="closeTipDv">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'tipDv',
  components: {},
  props: {
    tipDv: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    closeTipDv() {
      this.$emit('closeTipDv')
    }
  }
}
</script>

<style scoped lang="scss">
.tip-dv {
  ::v-deep.el-dialog__body {
    padding: 10px 20px !important;
  }
  .tip-content {
    .txt {
      position: relative;
      padding-left: 28px;
      line-height: 32px;
      font-size: 14px;
      i {
        position: absolute;
        top: 6px;
        left: 0;
        font-size: 20px;
        color: #f78b3e;
      }
      span {
        color: #ef929d;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 42px;
      color: #373e4f;
    }
    .confirm-btn {
      background: linear-gradient(0deg, #0831ff, #3777ff);
      color: #fff;
    }
  }
}
</style>
