<template>
   <el-dialog
    title="选择需要查询的账号"
    width="1200px"
    class="kx-dialog"
    max-height="300"
    :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="wrap">
      <el-table
        stripe
        style="width: 100%"
        border
        class="kx-table"
        @selection-change="handleSelectionChange"
        :data="list">
        <el-table-column
          type="index"
          width="50"
          label="序号">
        </el-table-column>
        <el-table-column prop="accountName" label="Name" width="160">
          <template slot-scope="scope">
            <span :class="scope.row.accountName != params.username ? 'color-red' : 'color-green'">
              {{ scope.row.accountName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="reviews" label="Reviews" width="160">
          <template slot-scope="scope">
            <span :class="scope.row.reviews != params.reviewNumber ? 'color-red' : 'color-green'">
              {{ scope.row.reviews }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="helpfulVotes" label="Helpful Vote" width="160">
          <template slot-scope="scope">
            <span :class="scope.row.helpfulVotes != params.helpfulVoteNumber ? 'color-red' : 'color-green'">
              {{ scope.row.helpfulVotes }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="Addr" width="160">
          <template slot-scope="scope">
            <span :class="scope.row.address != params.location ? 'color-red' : 'color-green'">
              {{ scope.row.address }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="latestReviewDate" label="Reviews Date 1" width="">
          <template slot-scope="scope">
            <!-- lastReviewDate -->
            <span :class="scope.row.latestReviewDate != params.before2monthReviewDate ? 'color-red' : 'color-green'">
              {{ scope.row.latestReviewDate }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          type="selection"
          width="90"
          label="选择" >
          <template slot="header">
            <span>选择_1644</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="l-height-24 f14 color-A3 mt10">
        <p> 提示：</p>
        <div >
          1、每个账号被选择查询将消耗1积分；<br />
          2、账号与查询条件匹配关系，匹配上标记绿色，匹配不上标记为红色；<br />
          3、相同的查询条件72小时内重查不消耗积分；<br />
          4、查询记录入口：
          <el-button class="kx-but" type="text" @click="goToUser">[我的账户]</el-button>
          -
          <el-button class="kx-but" type="text" @click="goToRecharge">[查询记录]</el-button>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: right;">
      <el-button round @click="handleClose" class="kxe-btn">取 消</el-button>
      <el-button
        round 
        type="primary" 
        class="kxe-btn kx-button"
        v-pointerReClick 
        @click="onSubmit">我已知晓规则并确认查询</el-button>
    </div>
  </el-dialog>
  
</template>

<script>
export default {
  name: 'accountsList',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selection: [],
    }
  },
  computed: {},
  watch: {
    visible: {
      handler(val) {
        if (val) this.selection = []
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    goToUser() {
      const newPage = this.$router.resolve({ name: 'UserInfo' })
      window.open(newPage.href, '_blank')
      // this.$router.push('/user-info')
    },
    goToRecharge() {
      const newPage = this.$router.resolve({ name: 'Recharge' })
      window.open(newPage.href, '_blank')
      // this.$router.push('/recharge')
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    handleSelectionChange(config) {
      this.selection = config
    },
    onSubmit() {
      const { selection } = this
      const accounts = selection.reduce((str, { accountNo }) => {
        str += `${str ? ',' : ''}${accountNo}`
        return str
      }, '')
      if (!accounts) return this.$message.warning('请至少选择一条信息')
      this.$emit('update-accounts', accounts)
      this.$emit('updateSelection', selection)
      this.handleClose()
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep.kx-table {
  tbody {
    .el-table-column--selection {
      text-align: center;
    }
  }
  thead { 
    .el-table-column--selection {
      position: relative;
      padding-left: 40px;
      &:after {
        content: '选择';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        line-height: 48px;
        width: 60px;
        text-align: center;
      }
    }
  }
}
.kxe-btn {
  width: 206px;
  padding: 0;
  line-height: 36px;
}
.kxe-btn {
  .el-button--primary {
    background-color: #3C7CFF;
    border-color: #3C7CFF;
  }
}
</style>
