<template>
  <div>
    <!-- <div class="page-right-icon">
    <div>
      <div
        class="pivot-wrap flex-center-wrap flex-flow"
        @mouseover="handleOver"
        @mouseout="handleOut">
        <i class="icon-wechat"></i>
        <div class="f12 -mt15">客户经理</div>
      </div>
      <div class="left-show" v-if="isShow">
        <img src="../../assets/home/client-code.png" alt="" />
        <div class="txt">客户经理</div>
      </div>
    </div>
  </div> -->
    <div class="page-right-icon">
      <el-popover placement="right" width="142" popper-class="right-icon-side-box" trigger="hover">
        <div class="content-box left-show-box">
          <img class="w-full" src="../../assets/home/managaer-qrcode-1.png" alt="" />
          <div class="txt">客户经理</div>
        </div>
        <div slot="reference" class="pivot-wrap flex-center-wrap flex-flow">
          <i class="icon-wechat"></i>
          <div class="f12 -mt15">客户经理</div>
        </div>
      </el-popover>
    </div>
    <!--
    <div class="page-right-icon top520">
      <el-popover placement="right" width="202" popper-class="right-icon-side-box" trigger="hover">
        <div class="content-box">
          <img class="w-full" src="../../assets/home/CEO-code.png" alt="" />
        </div>
        <div slot="reference" class="pivot-wrap flex-center-wrap flex-flow">
          <i class="icon-wechat"></i>
          <div class="f12 -mt15">CEO微信</div>
        </div>
      </el-popover>
    </div>
    -->
    <div class="page-right-icon top420">
      <el-popover placement="right" width="142" popper-class="right-icon-side-box" trigger="hover">
        <div class="content-box">
          <img class="w-full" src="../../assets/home/com-group.png" alt="" />
        </div>
        <div slot="reference" class="pivot-wrap flex-center-wrap flex-flow">
          <i class="icon-wechat"></i>
          <div class="f12 -mt15">卖家交流群</div>
        </div>
      </el-popover>
    </div>
    <!-- 贝贝二维码 -->
<!--    <div class="page-right-icon top520">-->
<!--      <el-popover placement="right" width="202" popper-class="right-icon-side-box" trigger="hover">-->
<!--        <div class="content-box">-->
<!--          <img class="w-full" src="../../assets/home/ceo_t2.png" alt="" />-->
<!--        </div>-->
<!--        <div slot="reference" class="pivot-wrap flex-center-wrap flex-flow">-->
<!--          <i class="icon-wechat"></i>-->
<!--          <div class="f12 -mt15 text-align-center">查链接测评</div>-->
<!--        </div>-->
<!--      </el-popover>-->
<!--    </div>-->

  </div>
</template>

<script>
export default {
  name: 'rightIcon',
  components: {},
  props: {},
  data() {
    return {
      isShow: true
    }
  },
  created() {

  },
  methods: {
    handleOver() {
      this.isShow = true
    },
    handleOut() {
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.page-right-icon {
  position: fixed;
  /**
  top: 420px;
  */
  top: 320px;
  right: 8px;
  z-index: 99999;
  cursor: pointer;

  &.top520 {
    top: 520px;
  }

  &.top420 {
    top: 420px;
  }

  >div {
    position: relative;
    /* width: 50px;
    height: 50px; */
    margin-bottom: 10px;
    border-radius: 4px;
    // overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .left-show {
      position: absolute;
      left: -180px;
      /* top: -48px; */
      top: -42px;
      width: 195px;
      height: 200px;
      background: url('../../assets/home/right-icon-bg.png');
      background-size: 100% 100%;

      img {
        position: absolute;
        top: 20px;
        left: 38px;
        width: 120px;
        height: 120px;
      }

      .txt {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #373e4f;
        margin-top: 140px;
      }
    }
  }
}

.pivot-wrap {
  width: 70px;
  height: 70px;
  /* border: 1px solid #21ffa1; */
  /* background-color: #03e06e; */
  background: linear-gradient(0deg, #04AE0F, #13DC20);
  border-radius: 6px;
  color: #ffffff;
}

.left-show-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 120px;
    margin-top: 10px
  }

  .txt {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #373e4f;
    padding-bottom: 5px;
  }
}
</style>
