<template>
  <div class="old-data">
    <div class="container">
      <div class="title">
        <i class="svg"></i>
        截止昨日数据
      </div>
      <!-- <div class="content-box">
        <div class="left-box">
          <div class="account">
            <div class="add">昨日新增<span>{{oldData.problemAccount}}</span>个问题账号</div>
            <div class="add-black">新增<span>{{oldData.blacklistAccountNum}}</span>个举报黑名单账号</div>
          </div>
          <div class="ratio">
            通过平台问题账号被查出比例<span>{{oldData.platformProblemAccountRate}}</span>，被举报黑名单账号查出比例<span
              >{{oldData.reportedBlacklistRate}}</span
            >
          </div>
        </div>
        <div class="right-box">
          <div class="num-account">
            平台累计收录<span>{{oldData.totalBlacklistAccountNum}}</span>个黑名单账号，其中为用户自行举报的黑名单账号有<span>{{oldData.totalSelfBlacklistAccountNum}}</span>个
          </div>
        </div>
      </div> -->

      <div class="content-box">
        <div class="list-box">
          <div class="left-item">昨日新增<span>{{oldData.yesterdayProblemAccount}}</span>个问题账号</div>
          <div class="right-item">
            截止到昨日，平台累计收录<span>{{oldData.totalProblemAccount}}</span>个问题账号
          </div>
        </div>
        <div class="list-box">
          <div class="left-item">昨日新增<span>{{oldData.yesterdayBlacklistAccount}}</span>个举报黑名单账号</div>
          <div class="right-item">
            截止到昨日，平台累计收录<span>{{oldData.totalBlacklistAccount}}</span>个举报黑名单账号
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { yesterdayReport } from '@/api/home.js'
export default {
  name: 'oldData',
  components: {},
  props: {},
  data() {
    return {
      oldData: {}
    }
  },
  computed: {},
  watch: {},
  created() {
    this.yesterdayReport()
  },
  mounted() {

  },
  methods: {
    async yesterdayReport() {
      let res = await yesterdayReport()
      if (res.success) {
        this.oldData = res.data
      }
    }
  }
}
</script>

<style scoped lang="scss">
.old-data {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .container {
    width: 1200px;
    padding: 20px 50px 0 20px;
    background: #fefefe;
    box-shadow: 0px 2px 12px 0px rgba(216, 221, 235, 0.6);
    border-radius: 4px;
    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: #24232f;
      .svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../../../assets/home/new-data.svg') no-repeat;
        background-size: 100% 100%;
        margin-right: 8px;
      }
    }
    .content-box {
      margin-top: 16px;
      .list-box {
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #24232f;
        margin-bottom: 20px;
        .left-item,
        .right-item {
          width: 545px;
          height: 50px;
          padding: 12px 16px;
          background-color: #f3f7ff;
          border-radius: 4px;
          span {
            color: #FF455B;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
