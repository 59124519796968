<template>
  <el-dialog
    title="查询提示"
    :visible.sync="queryDv"
    width="650px"
    :before-close="closeQueryDV"
    :close-on-click-modal="false"
    class="close-mySelect-DV"
  >
    <div class="DV-content" v-if="queryStatus === 1">
      <i class="el-icon-error"></i>
      <div class="txt">
        <div>积分余额不足，扣除失败</div>
      </div>
    </div>
    <div class="new-select-content" v-else-if="queryStatus === 0">
      <div class="item">
        1、本次查询将消耗<span>{{ options.searchPrice }}积分</span
        >，查询结果为空不消耗积分；
      </div>
      <div class="item">
        2、相同的查询条件<span>72小时</span>内重查不消耗积分；
      </div>
      <div class="item">3、查询记录入口：[我的帐户]-[查询记录]。</div>
    </div>
    <div class="length-num" v-if="queryStatus === 2">
      <i class="el-icon-warning"></i>本次查询出<span
        >{{ this.options.totalRecord }}条</span
      >记录需消耗<span>{{ options.searchPrice * options.feeRecord }}积分</span
      >，请确认是否继续查看
    </div>
    <div class="length-num" v-if="queryStatus === 3">
      <i class="el-icon-warning red"></i
      >查询结果<span>超过50条</span>，请输入查询界面更多指标(Name/Reviews/Helpful
      Votes/Addr/Reviews)或24小时之后再来查询
    </div>
    <!--
    <div class="length-num" v-if="queryStatus === 3">
      <i class="el-icon-warning red"></i
      >查询结果<span>超过5条</span>，请输入更精确的查询条件，或者使用Profile ID
      查询
    </div>
    -->
    <div class="length-num d-flex" v-if="queryStatus === 4">
      <i class="el-icon-warning red"></i>
      <div class="l-height-24">
        <p>
          亲，您好！由于您查询结果不是非常精准，有多个满足要求的买家账号，您可以选择以下两种方式解决：
        </p>
        <p class="mt5">第一：多次尝试，填写更精准的搜索指标。</p>
        <p class="mt5">
          第二：我们系统会立即帮您做详细匹配分析，您3小时后再来重新搜索，就可得到您想要的结果。
        </p>
      </div>
    </div>
    <span
      slot="footer"
      :class="['dialog-footer', queryStatus === 3 ? 'footer-end' : '']"
    >
      <el-checkbox v-model="checkBoxDV" v-if="isShow && queryStatus == 0"
        >不再提示</el-checkbox
      >
      <el-button round @click="closeQueryDV" v-if="queryStatus !== 3"
        >取 消</el-button
      >
      <el-button
        @click="isSearch(0)"
        round
        type="primary"
        class="kxe-btn kx-button"
        v-if="queryStatus === 0"
        >我已知晓规则并确认查询</el-button
      >
      <el-button
        round
        @click="isSearch(1)"
        class="to-up"
        v-if="queryStatus === 1"
        >去充值</el-button
      >
      <el-button
        @click="isSearch(2)"
        round
        type="primary"
        class="kxe-btn kx-button"
        v-if="queryStatus === 2"
        >继续</el-button
      >
      <el-button
        @click="onConfirm()"
        round
        type="primary"
        class="kxe-btn kx-button"
        v-if="queryStatus === 3 || queryStatus === 4"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { userConfig } from "@/api/home.js";
export default {
  name: "searchDv",
  components: {},
  props: {
    queryDv: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      queryStatus: 0, // 0：搜索金币、1：金币不足、2：搜索小于等于5、3：大于5
      checkBoxDV: false,
    };
  },
  computed: {
    isShow() {
      return this.options.searchType == 0
        ? this.options.searchNoticeFlag
        : this.options.queryNoticeFlag;
      // if (this.options.searchType == 0) {
      //   return this.options.searchNoticeFlag
      // } else {
      //   return this.options.queryNoticeFlag
      // }
    },
  },
  watch: {
    options: {
      handler() {
        const { totalRecord } = this.options;
        this.queryStatus = 0;
        /*
        if (totalRecord > 5) {
          this.queryStatus = 3
        } else if (totalRecord > 1) {
          this.queryStatus = 2
        } else {
          this.queryStatus = 0
        }
        */
        if (totalRecord > 1 && totalRecord <= 10) {
          this.queryStatus = 2;
        }

        if (totalRecord >= 11 && totalRecord <= 51) {
          this.queryStatus = 4;
        }

        if (totalRecord > 50) {
          this.queryStatus = 3;
        }

        this.checkBoxDV = false;
      },
    },
  },
  created() {
    // userConfig({
    //   configKey: 'search_notice_flag',
    //   configValue: 'true'
    // })
    // userConfig({
    //   configKey: 'query_notice_flag',
    //   configValue: 'true'
    // })
  },
  methods: {
    closeQueryDV() {
      this.$emit("closeQueryDV");
    },
    onConfirm() {
      // const { queryStatus } = this
      this.closeQueryDV();
      setTimeout(() => {
        this.queryStatus = 0;
      }, 500);
    },
    isSearch(type) {
      if (type === 0) {
        const {
          coinNum,
          giftCoinNum,
          searchPrice,
          giftFreeNum,
          searchFreeNum,
        } = this.options;
        if (
          coinNum + giftCoinNum + giftFreeNum + searchFreeNum - searchPrice >=
          0
        ) {
          // 直接搜索
          if (this.checkBoxDV) {
            let configKey =
              this.options.searchType == 0
                ? "search_notice_flag"
                : "query_notice_flag";
            userConfig({
              configKey,
              configValue: "false",
            });
          }
        }
        this.$emit("querySearch"); // 不管有没有余额 都查询 后端接口字段已做模糊化
      } else if (type === 1) {
        this.$router.push("/recharge");
      } else if (type === 2) {
        this.queryStatus = 0;
      } else {
        this.closeQueryDV();
        setTimeout(() => {
          this.queryStatus = 0;
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.close-mySelect-DV {
  ::v-deep.el-dialog__body {
    padding: 10px 20px !important;
  }
  .DV-success {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    .el-icon-success {
      font-size: 20px;
      color: #3fdaa0;
    }
    .text {
      margin-left: 5px;
      font-size: 18px;
      font-weight: 400;
      color: #373e4f;
      line-height: 24px;
      text-align: center;
    }
  }
  .DV-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .el-icon-error,
    .el-icon-warning {
      font-size: 40px;
      color: #ffc148;
    }

    .txt {
      font-size: 20px;
      font-weight: 400;
      color: #373e4f;
      line-height: 24px;
      margin-top: 20px;
      text-align: center;
      div:nth-child(1) {
        margin-bottom: 12px;
      }
    }
    .txt-width {
      width: 395px;
    }
  }
  .new-select-content {
    color: #373e4f;
    line-height: 36px;
    font-size: 16px;
    span {
      color: #ff455b;
    }
  }
  .length-num {
    font-size: 16px;
    color: #373e4f;
    i {
      color: #f78b3e;
      vertical-align: middle;
      font-size: 32px;
      margin-right: 12px;
    }
    .red {
      color: #e34256;
    }
    span {
      color: #ff455b;
    }
  }
  .el-dialog__footer {
    position: relative;
    padding: 0 32px 20px;
    .dialog-footer {
      display: flex;
      justify-content: center;
      .el-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        // height: 46px;
        // color: #373e4f;
      }
      .confirm-btn {
        background: linear-gradient(0deg, #0831ff, #3777ff);
        color: #fff;
      }
      .to-up {
        background: linear-gradient(0deg, #ff604e, #ffa853);
        color: #fff !important;
      }
      .el-checkbox {
        position: absolute;
        bottom: 32px;
        left: 22px;
      }
    }
    .footer-end {
      justify-content: flex-end;
    }
  }
}
</style>
