<template>
  <el-dialog
    title="举报"
    :visible.sync="reportDv"
    width="1000px"
    :before-close="closeReportDv"
    :close-on-click-modal="false"
    class="report-DV"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
  <el-tabs v-model="reportMode" @tab-click="onTabMode">
    <el-tab-pane label="单个举报" name="0">
    <el-form
      :model="reportForm"
      :rules="reportRules"
      ref="reportForm"
      label-width="120px"
      class="report-form"
      label-position="left"
    >
      <el-form-item label="举报账号类型" prop="reportType">
        <el-radio-group v-model="reportForm.reportType" @change="handleRadio">
          <el-radio label="0">
            举报买家账号
            <!-- 举报测评买家账号 -->
          </el-radio>
          <el-radio label="1">
            举报中介服务商
            <!-- 举报测评中介账号 -->
          </el-radio>
          <el-radio label="2">
            举报卖家
            <!-- 举报不诚信测评卖家 -->
          </el-radio>
           <el-radio label="4">
            举报恶意差评
            <!-- 举报不诚信测评卖家 -->
          </el-radio>
          <el-radio label="3">其他</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="举报理由" prop="reportReason" :required="reportForm.reportType != 4">
        <el-input
          v-model="reportForm.reportReason"
          v-if="reportForm.reportType == 3"
          maxlength="15"
          placeholder="请输入举报理由"
        ></el-input>
        <div v-if="reportForm.reportType == 4"  reportForm.reportReason='买家恶意给差评'>买家恶意给差评</div>
         <el-select
          v-model="reportForm.reportReason"
          placeholder="请选择举报类型"
          v-else
        >
          <el-option
            v-for="item in reasonOptions"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="举报账号信息" prop="type">
        <el-checkbox-group v-model="reportForm.type">
          <template v-if="reportForm.reportType == 0">
            <div class="content">
            <div class="left-input">
              <el-form-item class="report-message">
                <el-checkbox
                  label="profileId"
                  @change="(val) => handleCheck(val, 'profileId')"
                  >Profile ID</el-checkbox
                >
                <el-input
                  v-model.trim="reportForm.buyerAccount.profileId"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'profileId')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="amzUsername"
                  @change="(val) => handleCheck(val, 'amzUsername')"
                  >亚马逊会员名</el-checkbox
                >
                <el-input
                  v-model="reportForm.buyerAccount.amzUsername"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'amzUsername')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="email"
                  @change="(val) => handleCheck(val, 'email')"
                  >邮箱</el-checkbox
                >
                <el-input
                  v-model="reportForm.buyerAccount.email"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'email')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="facebook"
                  @change="(val) => handleCheck(val, 'facebook')"
                  >Facebook账号</el-checkbox
                >
                <el-input
                  v-model="reportForm.buyerAccount.facebook"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'facebook')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="wechat"
                  @change="(val) => handleCheck(val, 'wechat')"
                  >微信号</el-checkbox
                >
                <el-input
                  v-model="reportForm.buyerAccount.wechat"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'wechat')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="qq"
                  @change="(val) => handleCheck(val, 'qq')"
                  >QQ</el-checkbox
                >
                <el-input
                  v-model="reportForm.buyerAccount.qq"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'qq')"
                ></el-input>
              </el-form-item>
            </div>
            <div class="right-input">
              <el-form-item class="report-message">
                <el-checkbox
                  label="tiktok"
                  @change="(val) => handleCheck(val, 'tiktok')"
                  >Tiktok账号</el-checkbox
                >
                <el-input
                  v-model="reportForm.buyerAccount.tiktok"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'tiktok')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="twitter"
                  @change="(val) => handleCheck(val, 'twitter')"
                  >Twitter账号</el-checkbox
                >
                <el-input
                  v-model="reportForm.buyerAccount.twitter"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'twitter')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="instagram"
                  @change="(val) => handleCheck(val, 'instagram')"
                  >instagram</el-checkbox
                >
                <el-input
                  v-model.trim="reportForm.buyerAccount.instagram"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'instagram')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="whatsapp"
                  @change="(val) => handleCheck(val, 'whatsapp')"
                  >whatsapp</el-checkbox
                >
                <el-input
                  v-model.trim="reportForm.buyerAccount.whatsapp"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'whatsapp')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="other"
                  @change="(val) => handleCheck(val, 'other')"
                  >其他账号</el-checkbox
                >
                <el-input
                  v-model.trim="reportForm.buyerAccount.other"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'other')"
                ></el-input>
              </el-form-item>
            </div>
            </div>            
          </template>
          <template v-if="reportForm.reportType == 1">
            <el-form-item class="report-message">
              <el-checkbox
                label="wechat"
                @change="(val) => handleCheck(val, 'wechat')"
                >微信号</el-checkbox
              >
              <el-input
                v-model="reportForm.intermediaryAccount.wechat"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'wechat')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox label="qq" @change="(val) => handleCheck(val, 'qq')"
                >QQ</el-checkbox
              >
              <el-input
                v-model="reportForm.intermediaryAccount.qq"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'qq')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="mobile"
                @change="(val) => handleCheck(val, 'mobile')"
                >电话</el-checkbox
              >
              <el-input
                v-model="reportForm.intermediaryAccount.mobile"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'mobile')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="alipay"
                @change="(val) => handleCheck(val, 'alipay')"
                >支付宝账号</el-checkbox
              >
              <el-input
                v-model="reportForm.intermediaryAccount.alipay"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'alipay')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="email"
                @change="(val) => handleCheck(val, 'email')"
                >邮箱</el-checkbox
              >
              <el-input
                v-model="reportForm.intermediaryAccount.email"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'email')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="facebook"
                @change="(val) => handleCheck(val, 'facebook')"
                >Facebook账号</el-checkbox
              >
              <el-input
                v-model="reportForm.intermediaryAccount.facebook"
                placeholder="选填"
                maxlength="255"
                @input="(val) => handleInpChange(val, 'facebook')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="other"
                @change="(val) => handleCheck(val, 'other')"
                >其他账号</el-checkbox
              >
              <el-input
                v-model="reportForm.intermediaryAccount.other"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'other')"
              ></el-input>
            </el-form-item>
          </template>
          <template v-if="reportForm.reportType == 2">
            <el-form-item class="report-message">
              <el-checkbox
                label="shopName"
                @change="(val) => handleCheck(val, 'shopName')"
                >店铺名称</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.shopName"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'shopName')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="brand"
                @change="(val) => handleCheck(val, 'brand')"
                >卖家品牌</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.brand"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'brand')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="company"
                @change="(val) => handleCheck(val, 'company')"
                >公司名称</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.company"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'company')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="name"
                @change="(val) => handleCheck(val, 'name')"
                >姓名</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.name"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'name')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="mobile"
                @change="(val) => handleCheck(val, 'mobile')"
                >电话</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.mobile"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'mobile')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="wechat"
                @change="(val) => handleCheck(val, 'wechat')"
                >微信号</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.wechat"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'wechat')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox label="qq" @change="(val) => handleCheck(val, 'qq')"
                >QQ</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.qq"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'qq')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="email"
                @change="(val) => handleCheck(val, 'email')"
                >邮箱</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.email"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'email')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="other"
                @change="(val) => handleCheck(val, 'other')"
                >其他账号</el-checkbox
              >
              <el-input
                v-model="reportForm.sellerAccount.other"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'other')"
              ></el-input>
            </el-form-item>
          </template>
          <template v-if="reportForm.reportType == 3">
            <el-form-item class="report-message">
              <el-checkbox
                label="profileId"
                @change="(val) => handleCheck(val, 'profileId')"
                >Profile ID</el-checkbox
              >
              <el-input
                v-model="reportForm.reportOther.profileId"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'profileId')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="wechat"
                @change="(val) => handleCheck(val, 'wechat')"
                >微信号</el-checkbox
              >
              <el-input
                v-model="reportForm.reportOther.wechat"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'wechat')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox label="qq" @change="(val) => handleCheck(val, 'qq')"
                >QQ</el-checkbox
              >
              <el-input
                v-model="reportForm.reportOther.qq"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'qq')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="email"
                @change="(val) => handleCheck(val, 'email')"
                >邮箱</el-checkbox
              >
              <el-input
                v-model="reportForm.reportOther.email"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'email')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="mobile"
                @change="(val) => handleCheck(val, 'mobile')"
                >电话</el-checkbox
              >
              <el-input
                v-model="reportForm.reportOther.mobile"
                placeholder="选填"
                @input="(val) => handleInpChange(val, 'mobile')"
              ></el-input>
            </el-form-item>
            <el-form-item class="report-message">
              <el-checkbox
                label="other"
                @change="(val) => handleCheck(val, 'other')"
                >其他账号</el-checkbox
              >
              <el-input
                v-model="reportForm.reportOther.other"
                placeholder="选填"
                maxlength="255"
                @input="(val) => handleInpChange(val, 'other')"
              ></el-input>
            </el-form-item>
          </template>
           <template v-if="reportForm.reportType == 4">
            <div class="content">
            <div class="left-input">
              <el-form-item class="report-message">
                <el-checkbox
                  label="profileId"
                  @change="(val) => handleCheck(val, 'profileId')"
                  >Profile ID</el-checkbox
                >
                <el-input
                  v-model.trim="reportForm.reportProEvaluator.profileId"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'profileId')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="amzUsername"
                  @change="(val) => handleCheck(val, 'amzUsername')"
                  >亚马逊会员名</el-checkbox
                >
                <el-input
                  v-model="reportForm.reportProEvaluator.amzUsername"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'amzUsername')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="email"
                  @change="(val) => handleCheck(val, 'email')"
                  >邮箱</el-checkbox
                >
                <el-input
                  v-model="reportForm.reportProEvaluator.email"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'email')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="facebook"
                  @change="(val) => handleCheck(val, 'facebook')"
                  >Facebook账号</el-checkbox
                >
                <el-input
                  v-model="reportForm.reportProEvaluator.facebook"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'facebook')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="wechat"
                  @change="(val) => handleCheck(val, 'wechat')"
                  >微信号</el-checkbox
                >
                <el-input
                  v-model="reportForm.reportProEvaluator.wechat"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'wechat')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="qq"
                  @change="(val) => handleCheck(val, 'qq')"
                  >QQ</el-checkbox
                >
                <el-input
                  v-model="reportForm.reportProEvaluator.qq"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'qq')"
                ></el-input>
              </el-form-item>
            </div>
            <div class="right-input">
              <el-form-item class="report-message">
                <el-checkbox
                  label="tiktok"
                  @change="(val) => handleCheck(val, 'tiktok')"
                  >Tiktok账号</el-checkbox
                >
                <el-input
                  v-model="reportForm.reportProEvaluator.tiktok"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'tiktok')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="twitter"
                  @change="(val) => handleCheck(val, 'twitter')"
                  >Twitter账号</el-checkbox
                >
                <el-input
                  v-model="reportForm.reportProEvaluator.twitter"
                  placeholder="选填"
                  @input="(val) => handleInpChange(val, 'twitter')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="instagram"
                  @change="(val) => handleCheck(val, 'instagram')"
                  >instagram</el-checkbox
                >
                <el-input
                  v-model.trim="reportForm.reportProEvaluator.instagram"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'instagram')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="whatsapp"
                  @change="(val) => handleCheck(val, 'whatsapp')"
                  >whatsapp</el-checkbox
                >
                <el-input
                  v-model.trim="reportForm.reportProEvaluator.whatsapp"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'whatsapp')"
                ></el-input>
              </el-form-item>
              <el-form-item class="report-message">
                <el-checkbox
                  label="other"
                  @change="(val) => handleCheck(val, 'other')"
                  >其他账号</el-checkbox
                >
                <el-input
                  v-model.trim="reportForm.reportProEvaluator.other"
                  placeholder="选填"
                  maxlength="255"
                  @input="(val) => handleInpChange(val, 'other')"
                ></el-input>
              </el-form-item>
            </div>
            </div>            
          </template>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="举报详情" prop="reportDetail">
        <el-input
          type="textarea"
          v-model="reportForm.reportDetail"
          placeholder="选填"
          maxlength="255"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="imageUris" v-loading="imgLoading" >
        <div class="img-list">
          <template v-if="reportForm.imageUris.length > 0">
            <div
              v-for="(item, index) in reportForm.imageUris"
              :key="index"
              class="avatar"
            >
              <img :src="item" />
              <i class="el-icon-error" @click="tapDeleteImg(index)"></i>
            </div>
          </template>
          <el-upload
            class="avatar-uploader"
            action="/api/upload/image"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :data="{ type: 'blacklist' }"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="format-type">仅支持jpg/png格式，文件大小2M以内</div>
      </el-form-item>
      <!-- <el-form-item label="验证码" class="code" prop="captcha" v-if="reportForm.reportType != 4">
        <el-input v-model="reportForm.captcha" placeholder="请输入"></el-input>
        <div class="img-code">
          <img :src="imgCode" @click="tapCutImg" />
        </div>
      </el-form-item> -->
    </el-form>
    
    </el-tab-pane>
    <el-tab-pane label="批量举报" name="1">
      <report-batch
        @on-success="(res) => uploadSuccess(res)"
        ref="reportBatch"/>
    </el-tab-pane>
  </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeReportDv">取 消</el-button>
      <el-button
        class="affirm-btn"
        v-preventReClick 
        @click="handleSubmit"
        v-if="reportMode === '0'">确 定</el-button>
      <el-button
        class="affirm-btn"
        v-preventReClick
        v-else
       @click="onSubmitBatch">导 入</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { reportTypes, reportSubmit } from '@/api/home.js'
import ReportBatch from './report-batch.vue'

export default {
  name: 'reportDv',
  components: {
    ReportBatch,
  },
  props: {
    reportDv: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let validatereportMessage = (rule, val, callback) => {
      if (val.length == 0) {
        callback(new Error('至少选择一个'));
      } else {
        if (this.reportForm.reportType == 0) {
          if (Object.values(this.reportForm.buyerAccount).length == 0) {
            callback(new Error('至少选择一个，并输入内容'));
          } else {
            callback();
          }
        } else if (this.reportForm.reportType == 1) {
          if (Object.values(this.reportForm.intermediaryAccount).length == 0) {
            callback(new Error('至少选择一个，并输入内容'));
          } else {
            callback();
          }
        } else if (this.reportForm.reportType == 2) {
          if (Object.values(this.reportForm.sellerAccount).length == 0) {
            callback(new Error('至少选择一个，并输入内容'));
          } else {
            callback();
          }
        } else if (this.reportForm.reportType == 3) {
          if (Object.values(this.reportForm.reportOther).length == 0) {
            callback(new Error('至少选择一个，并输入内容'));
          } else {
            callback();
          }
        } else if (this.reportForm.reportType == 4) {
            if (Object.values(this.reportForm.reportProEvaluator).length == 0) {
                callback(new Error('至少选择一个，并输入内容'));
            } else {
                callback();
            }
        }
      }
    };
  //   let validateName = (rule, value, callback) => {
  //   if (this.reportForm.imageUris == "" && this.reportForm.reportType != 4) {
  //     callback(new Error("至少上传一张图片"));
  //   } else {
  //     callback();
  //   }
  // };
    let validaterep = (rule, value, callback) => {
    if (this.reportForm.reportReason == "" && this.reportForm.reportType != 4) {
      callback(new Error("请选择举报理由"));
    } else {
      callback();
    }
  };
    return {
      loading: false,
      loadingText: '',
      reportMode: '0',
      reportForm: {
        reportType: '0',
        reportReason: '',
        reportDetail: '',
        imageUris: [],
        type: [],
        // captcha: '',
        buyerAccount: {
          profileId: '',
          amzUsername: '',
          email: '',
          facebook: '',
          wechat: '',
          qq: '',
          tiktok: '',
          twitter: '',
          instagram: '',
          whatsapp: '',
          other: ''
        }, // 举报买家
        intermediaryAccount: {
          wechat: '',
          qq: '',
          mobile: '',
          alipay: '',
          email: '',
          facebook: '',
          other: ''
        }, // 举报中介
        sellerAccount: {
          shopName: '',
          brand: '',
          company: '',
          name: '',
          mobile: '',
          wechat: '',
          qq: '',
          email: '',
          other: ''
        }, // 举报不诚信卖家
        reportProEvaluator: {
          profileId: '',
          amzUsername: '',
          email: '',
          facebook: '',
          wechat: '',
          qq: '',
          tiktok: '',
          twitter: '',
          instagram: '',
          whatsapp: '',
          other: ''
        }, // 举报不诚信卖家
        reportOther: {
          profileId: '',
          wechat: '',
          qq: '',
          email: '',
          mobile: '',
          other: ''
        } // 其他
      },
      reportRules: {
        reportType: [{ required: true, message: '请选择举报账号类型', trigger: 'blur' }],
        // reportReason: [{ required: true, message: '请选择举报理由', trigger: 'blur' }],
        reportReason:[{validator: validaterep, trigger: 'blur'}],
        type: [
          { validator: validatereportMessage, trigger: 'blur' },
          { required: true, message: '至少选择一个，并输入内容', trigger: 'blur' }
        ],
        // reportDetail: [{ required: true, message: '请输入举报详情', trigger: 'blur' }],
        // imageUris: [{ required: true, message: '至少上传一张图片', trigger: 'click' }],
        // imageUris:[{ validator: validateName,trigger: 'click' }],
        // captcha: [{ required: true, message: '请输入图片验证码', trigger: 'blur' }]
      },
      reasonOptions: [],
      imgCode: '/api/captcha/get?type=bindMobile',
      imgLoading: false
    }
  },
  computed: {},
  watch: {
    reportDv: {
      handler() {
        if (this.reportDv) {
          this.reportTypes()
          this.tapCutImg()
        }
      },
      immediate: true
    }
  },
  created() { },
  mounted() {

  },
  methods: {
    onTabMode() {
      // console.log(tab, event);
    },
    onSubmitBatch() {
      this.loading = true
      this.loadingText = '举报数据提交中，请稍后...'
      this.$refs.reportBatch.submit();
    },
    uploadSuccess(res) {
      this.loading = false
      const { success, message } = res
      if (!success) return this.$message.error(message)
      this.closeReportDv()
      this.$message.success(message)
    },
    closeReportDv() {
      this.$emit('closeReportDv')
      this.$refs['reportForm'].resetFields()
      this.reportForm = {
        reportType: '0',
        reportReason: '',
        reportDetail: '',
        imageUris: [],
        type: [],
        captcha: '',
        buyerAccount: {
          profileId: '',
          amzUsername: '',
          email: '',
          facebook: '',
          wechat: '',
          qq: '',
          tiktok: '',
          twitter: '',
          instagram: '',
          whatsapp: '',
          other: ''
        }, // 举报买家
        intermediaryAccount: {
          wechat: '',
          qq: '',
          mobile: '',
          alipay: '',
          email: '',
          facebook: '',
          other: ''
        }, // 举报中介
        sellerAccount: {
          shopName: '',
          brand: '',
          company: '',
          name: '',
          mobile: '',
          wechat: '',
          qq: '',
          email: '',
          other: ''
        }, // 举报不诚信卖家
        reportProEvaluator: {
            profileId: '',
            amzUsername: '',
            email: '',
            facebook: '',
            wechat: '',
            qq: '',
            tiktok: '',
            twitter: '',
            instagram: '',
            whatsapp: '',
            other: ''
        }, // 举报买家恶意给差评
        reportOther: {
          profileId: '',
          wechat: '',
          qq: '',
          email: '',
          mobile: '',
          other: ''
        } // 其他
      }
    },
    async reportTypes() {
      let res = await reportTypes({ type: this.reportForm.reportType })
      if (res.success) {
        this.reasonOptions = res.data
      }
    },
    handleRadio(val) {
      this.$refs['reportForm'].resetFields()
      this.reportForm.reportType = val
      this.reportForm.type = []
      // this.reportForm.captcha = ''
      if (this.reportForm.reportType == 0) {
        this.reportForm.buyerAccount = {
          profileId: '',
          amzUsername: '',
          email: '',
          facebook: '',
          wechat: '',
          qq: '',
          tiktok: '',
          twitter: '',
          instagram: '',
          whatsapp: '',
          other: ''
        }
      } else if (this.reportForm.reportType == 1) {
        this.reportForm.intermediaryAccount = {
          wechat: '',
          qq: '',
          mobile: '',
          alipay: '',
          email: '',
          facebook: '',
          other: ''
        }
      } else if (this.reportForm.reportType == 2) {
        this.reportForm.sellerAccount = {
          shopName: '',
          brand: '',
          company: '',
          name: '',
          mobile: '',
          wechat: '',
          qq: '',
          email: '',
          other: ''
        }
      } else if (this.reportForm.reportType == 4) {
        this.reportForm.reportProEvaluator = {
          profileId: '',
          amzUsername: '',
          email: '',
          facebook: '',
          wechat: '',
          qq: '',
          tiktok: '',
          twitter: '',
          instagram: '',
          whatsapp: '',
          other: ''
        }
      } else {
        this.reportForm.reportOther = {
          profileId: '',
          wechat: '',
          qq: '',
          email: '',
          mobile: '',
          other: ''
        }
      }
      this.reportTypes()
    },
    handleSubmit() {
      /* let profileId = this.reportForm.buyerAccount.profileId
      if (profileId && profileId.length > 28) {
        this.$message.warning('请输入正确的profile id,如果有问题请联系，右侧的客户经理。')
        return
      } */
        // console.log('=======');
        this.$refs['reportForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          this.loadingText = '举报数据提交中，请稍后...'
            // console.log('=======' + this.reportForm.reportType);
          if(this.reportForm.reportType==4){
            this.reportForm.reportReason='买家恶意给差评'
            if (this.reportForm.reportDetail.trim() === '') {
              this.reportForm.reportDetail='买家恶意给差评';
            }
            // this.reportForm.reportProEvaluator=this.reportForm.buyerAccount
          }
          const params = { ...this.reportForm }
          const config = {
            '0': 'buyerAccount',
            '1': 'intermediaryAccount',
            '2': 'sellerAccount',
            '3': 'reportOther',
            '4': 'reportProEvaluator',
          }
          let key = config[params.reportType]
          if (key) {
            Object.values(config).forEach(el => {
              if (el !== key) {
                delete params[el]
              }
            })
          }
          let res = await reportSubmit(params)
          this.loading = false
          if (res.success) {
            this.$message.success('举报成功')
            this.closeReportDv()
          }
        } else {
          return false;
        }
      });
    },
    handleInpChange(val, id) {
      if (val.trim().length == 0) {
        this.reportForm.type = this.reportForm.type.filter(item => item != id)
      } else {
        if (!this.reportForm.type.includes(id)) {
          this.reportForm.type.push(id)
        }
      }
    },
    handleCheck(val, id) {
      if (!val) {
        if (this.reportForm.reportType == 0) {
          this.reportForm.buyerAccount[id] = ''
        } else if (this.reportForm.reportType == 1) {
          this.reportForm.intermediaryAccount[id] = ''
        } else if (this.reportForm.reportType == 2) {
          this.reportForm.sellerAccount[id] = ''
        } else if (this.reportForm.reportType == 3) {
          this.reportForm.reportOther[id] = ''
        } else if (this.reportForm.reportType == 4) {
            this.reportForm.reportProEvaluator[id] = ''
        }
      }
    },
    tapCutImg() {
      this.imgCode = '/api/captcha/get?type=bindMobile' + (+new Date())
    },
    // 获取图片
    handleAvatarSuccess(res) {
      // console.log(file);
      this.imgLoading = false
      if (res.success) {
        // URL.createObjectURL(file.raw)
        this.reportForm.imageUris.push(res.data)
        this.$message.success('图片上传成功')
      }
    },
    // 验证图片信息
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt5M = (file.size / 1024 / 1024) < 5;
      // console.log(file.size / 1024 / 1024);
      if (!isJPG) this.$message.error('上传头像图片只能是JPG和PNG格式!');
      if (!isLt5M) this.$message.error('上传头像图片大小不能超过 5MB!');
      this.imgLoading = true
      return isJPG && isLt5M;
    },
    // 点击删除图片
    tapDeleteImg(index) {
      this.$confirm('您确认要删除该张图片吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message.closeAll()
        this.reportForm.imageUris.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      });
    },
  }
}
</script>

<style scoped lang="scss">
.report-DV {
  ::v-deep .el-dialog__body {
    padding-top: 10px;
    
    .el-tabs__nav-wrap {
      &:after {
        content: unset;
      }
    }
  }


  ::v-deep.el-dialog__header {
    border-bottom: 1px solid #e5e4ee;
  }
  .report-form {
    ::v-deep.el-input__inner {
      width: 260px;
      height: 36px;
    }
    .content {
      display: flex;
      justify-content: space-between;
    }
    .report-message {
      margin-bottom: 18px;
      ::v-deep.el-form-item__content {
        display: flex;
        .el-checkbox {
          width: 180px;
        }
      }
    }
    ::v-deep.el-textarea {
      position: relative;
      .el-textarea__inner {
        height: 84px;
      }
      .el-input__count {
        position: absolute;
        right: 15px;
        bottom: -5px;
        background: transparent;
      }
    }
    ::v-deep.img-list {
      display: flex;
      .avatar {
        position: relative;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
        i {
          position: absolute;
          bottom: 0;
          right: 0;
          cursor: pointer;
        }
        i.el-icon-error {
          color: #FF455B;
          top: -8px;
          right: -8px;
          font-size: 16px;
        }
      }
      .format-type {
        height: 30px;
        font-size: 14px;
        margin: -20px 0 0 28px;
      }
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #0668f6;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
      }
      .el-upload__tip {
        margin: -20px 0 0 25px;
      }
    }
    .code {
      position: relative;
      .img-code {
        position: absolute;
        top: 3px;
        left: 305px;
        width: 72px;
        height: 36px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 36px;
      border-radius: 18px;
    }
    .affirm-btn {
      background-color: #3c7cff;
      color: #fff;
    }
  }
}
</style>
