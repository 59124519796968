<template>
  <div class="report-container">
    <!-- <div class="footer-tip" v-if="reportStatus === 1">
      <div>
        今日已收到{{
          totalReport.todayReportNum
        }}次举报&nbsp;&nbsp;&nbsp;累计收到{{ totalReport.totalReportNum }}次举报
      </div>
    </div> -->
    <div class="container" v-if="reportStatus === 1">
      <div
        class="report-list"
        v-for="item in reportList"
        :key="item.blacklistId"
      >
        <div class="report-item">
          <div class="left-label">举报时间</div>
          <div class="right-txt">{{ item.createDate }}</div>
        </div>
        <div class="report-item">
          <div class="left-label">举报账号类型</div>
          <div class="right-txt">{{ getAccountType(item.reportType) }}</div>
        </div>
        <div class="report-item">
          <div class="left-label">举报理由</div>
          <div class="right-txt">{{ item.reportReason }}</div>
        </div>
        <div class="report-item">
          <div class="left-label">举报账号信息</div>
          <div class="right-txt">
            {{ getReportAccountInfo(item.reportAccountInfo) }}
          </div>
        </div>
        <div class="report-item">
          <div class="left-label">举报详情</div>
          <div class="right-txt">
            {{ item.reportDetail }}
          </div>
        </div>
        <div class="report-item">
          <div class="left-label">举报图片</div>
          <div class="right-txt">
            <template v-for="(items, index) in item.imageUri">
              <el-popover
                placement="right-start"
                width="306"
                trigger="hover"
                :key="index"
              >
                <img :src="items" class="popver-img" />
                <img
                  :src="items"
                  alt=""
                  slot="reference"
                  class="complaint-img"
                />
              </el-popover>
            </template>
          </div>
        </div>
        <div class="f14 mt10 color-A3">
          此举报为举报人独立观点，不代表快现形立场。如发现有侵权或者恶意举报，请联系我们。
        </div>
      </div>
      <div class="block">
        <el-pagination
          layout="prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="1"
          background
          :page-size="10"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else-if="showReportSample">
      <report-sample />
    </div>
    <div class="report-null" v-else>
      <img
        src="../../../assets/home/report-null.png"
        alt=""
        v-if="reportStatus == 0"
      />
      <img src="../../../assets/home/report-null2.png" alt="" v-else />
      <span>{{
        reportStatus === 0 ? '免费查询举报信息！' : '该账号暂未收到任何举报！'
      }}</span>
    </div>
  </div>
</template>

<script>
import { reportSummary } from '@/api/home.js'
import ReportSample from './ReportSample'
export default {
  name: 'Report',
  components: { ReportSample },
  props: {
    showReportSample: {
      type: Boolean,
      default: true,
    },
    reportStatus: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    reportList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      totalReport: {
        totalReportNum: 0,
        todayReportNum: 0
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    // this.reportSummary()
  },
  methods: {
    async reportSummary () {
      let res = await reportSummary()
      if (res.success) {
        this.totalReport = res.data
      } else {
        this.$message.closeAll()
      }
    },
    getAccountType (val) {
      const obj = {
        '0': '举报测评买家账号',
        '1': '举报测评中介账号',
        '2': '举报不诚信测评卖家',
        '3': '其他'
      }
      return obj[val]
    },
    getReportAccountInfo (item) {
      let str = ''
      item = JSON.parse(item)
      for (let k in item) {
        if (k == 'profileId' && item[k]) {
          str += 'Profile ID：' + item[k] + '；'
        } else if (k == 'amzUsername' && item[k]) {
          str += '亚马逊会员名：' + item[k] + '；'
        } else if (k == 'email' && item[k]) {
          str += '邮箱：' + item[k] + '；'
        } else if (k == 'facebook' && item[k]) {
          str += 'Facebook账号：' + item[k] + '；'
        } else if (k == 'wechat' && item[k]) {
          str += '微信号：' + item[k] + '；'
        } else if (k == 'qq' && item[k]) {
          str += 'QQ：' + item[k] + '；'
        } else if (k == 'tiktok' && item[k]) {
          str += 'Tiktok账号：' + item[k] + '；'
        } else if (k == 'twitter' && item[k]) {
          str += 'Twitter账号：' + item[k] + '；'
        } else if (k == 'instagram' && item[k]) {
          str += 'instagram：' + item[k] + '；'
        } else if (k == 'whatsapp' && item[k]) {
          str += 'whatsapp：' + item[k] + '；'
        } else if (k == 'other' && item[k]) {
          str += '其他账号：' + item[k] + '；'
        } else if (k == 'mobile' && item[k]) {
          str += '电话：' + item[k] + '；'
        } else if (k == 'alipay' && item[k]) {
          str += '支付宝账号：' + item[k] + '；'
        } else if (k == 'shopName' && item[k]) {
          str += '店铺名称：' + item[k] + '；'
        } else if (k == 'brand' && item[k]) {
          str += '卖家品牌：' + item[k] + '；'
        } else if (k == 'company' && item[k]) {
          str += '公司名称：' + item[k] + '；'
        } else if (k == 'name' && item[k]) {
          str += '姓名：' + item[k] + '；'
        }
      }
      return str
    },
    handleSizeChange (val) {
      this.$emit('handleSizeChange', val)
    },
    handleCurrentChange (val) {
      this.$emit('handleCurrentChange', val)
    }
  }
}
</script>

<style scoped lang="scss">
.report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  .container {
    .report-list {
      position: relative;
      width: 1200px;
      background: #fefefe;
      box-shadow: 0px 2px 12px 0px rgba(216, 221, 235, 0.6);
      border-radius: 4px;
      font-size: 14px;
      padding: 30px;
      margin-bottom: 20px;
      .report-item {
        display: flex;
        .left-label {
          width: 85px;
          font-weight: 400;
          color: #a3aab7;
          line-height: 43px;
          margin-right: 35px;
        }
        .right-txt {
          font-weight: 400;
          color: #24232f;
          line-height: 43px;
          img {
            width: 80px;
            height: 80px;
            margin-right: 18px;
          }
        }
        &:nth-child(6) {
          .right-txt {
            display: flex;
            padding-top: 10px;
          }
        }
      }
    }
    .block {
      display: flex;
      justify-content: center;
      ::v-deep.active {
        background-color: #3c7cff !important;
        color: #fff !important;
      }
      ::v-deep.el-pager {
        li {
          background-color: #fff;
          border: 1px solid #e8e9f0;
        }
      }
      ::v-deep {
        .btn-prev,
        .btn-next {
          background-color: #fff;
          border: 1px solid #e8e9f0;
        }
      }
    }
  }
  .report-null {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;
    background: #fefefe;
    box-shadow: 0px 2px 12px 0px rgba(216, 221, 235, 0.6);
    border-radius: 4px;
    padding-top: 20px;
    padding-bottom: 30px;
    img {
      width: 193px;
      height: 198px;
    }
    span {
      font-size: 16px;
      color: #a3aab7;
      line-height: 30px;
      margin-top: 8px;
    }
  }
  // .footer-tip {
  //   width: 1200px;
  //   height: 36px;
  //   line-height: 36px;
  //   padding: 0 20px;
  //   background-color: #fff3e8;
  //   font-weight: 400;
  //   color: #ff9834;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   div {
  //     width: 100%;
  //     animation: move 12s linear infinite;
  //   }
  // }
  // @keyframes move {
  //   0% {
  //     transform: translateX(0);
  //   }
  //   100% {
  //     transform: translateX(100%);
  //   }
  // }
}
.popver-img {
  width: 280px;
  height: 280px;
}
</style>
