<template>
  <div class="kx-container">
    <el-carousel
      class="veido-carousel-wrap"
      indicator-position="none"
      :arrow="carouselArrow"
      :interval="5000" 
      :height="'160px'">
      <el-carousel-item v-for="(list, index) in carouselList" :key="index">
        <template v-if="list">
          <div class="carousel-list-wrap">
            <div
              :class="item.id ? 'the-cell' : 'the-cell seat-cell'"
              v-for="item in list" :key="item.id"
              @click="playVideo(item)">
              <i class="the-mask"></i>
              <div class="btn-center-wrap">
                <i class="icon-play btn-play"></i>
              </div>
              <img v-if="item.id" :src="item.posterUrl"/>
            </div>
          </div>
        </template>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js'
import { groupArray } from '@/utils'

import { 
  getTutorialVideos,
  recordVideos,
} from '@/api/course.js'
export default {
  name: 'VideoSwiper',
  components: {},
  props: {},
  data() {
    return {
      carouselList: [],
    }
  },
  computed: {
    carouselArrow() {
      const { carouselList } = this
      return carouselList.length > 1 ? 'hover' : 'never'
    },
  },
  watch: {},
  created() {
    this.getTutorialVideos()
  },
  mounted() {
  },
  methods: {
    getTutorialVideos() {
      getTutorialVideos().then((res) => {
        if (res.success) {
          const carouselList = groupArray([...res.data], 5)
          this.carouselList = carouselList
          console.log('carouselList', carouselList)
        }
      })
    },
    playVideo(item) {
      window.open(item.url, '_blank')
      const { id } = item
      getItem('userInfo') && id && recordVideos({
        type: 'video',
        id,
      })
    },
  }
}
</script>

<style scoped lang="scss">
.veido-carousel-wrap {
  height: 160px;
  margin-bottom: 30px;
}
.carousel-list-wrap {
  display: flex;
  justify-content: space-between;
  .the-cell {
    width: 216px;
    font-size: 0;
    cursor: pointer;
    position: relative;
    &.seat-cell {
      height: 0;
    }
    .btn-center-wrap {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .the-mask {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.3);
    }
    .icon-play{
      width: 38px;
      height: 40px;
     }
    &:hover {
      .btn-play {
        transform: scale(1.1);
      }
      .the-mask {
        background-color: rgba(0,0,0,.6);
      }
    }
    /* &:hover {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
      }
    } */
     /* &.nocursor {
      &:hover {
        &:after {
          content: '';
          display: none;
        }
      }
    } */
    img {
      width: 100%;
      height: 160px;
      border-radius: 5px;
    }
  }
}
</style>
