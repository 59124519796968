<template>
  <div class="icon-star-wrap">
    <div class="icon-statr-bg"></div>
    <div
      :style="style"
      class="icon-statr-bright">
    </div>
  </div>
</template>

<script>
import { getPercent } from '@/utils'
export default {
  name: 'IconStart', // 评分
  props: {
    star: {
      type: [Number, String],
      default: 0,
    },
    // 总数是多少
    level: {
      type: [Number, String],
      default: 5,
    },
    ratio: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    style() {
      const { ratio, star, level } = this
      let width = 0
      if (ratio) {
        width = ratio + '%'
      } else {
        width = getPercent(star, level)
      }
      
      return `width: ${width};`
    },
  },
  created() {},
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.icon-star-wrap {
  position: relative;
  display: inline-block;
  /* width: 98px; */
  .icon-statr-bg {
    width: 96px;
    height: 16px;
    background-image: url('~@/assets/icons/a-icon-star.png');
  }
  .icon-statr-bright {
    width: 96px;
    height: 16px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-image: url('~@/assets/icons/a-icon-star-bright.png');
  }
  img {
    width: 100%;
  }
}
</style>