<template>
  <div>
    <el-popover
      v-if="row.topRankingFloatingLayer"
      placement="bottom"
      trigger="hover"
      :content="row.topRankingFloatingLayer"
    >
      <span slot="reference">{{
        row.topRankingUseing || "****"
      }}</span>
    </el-popover>
    <span v-else>{{ row.topRankingUseing || "****" }}</span> 

    <svg-icon
      v-if="row.topRankingUseing"
      v-pointerReClick
      icon-class="lock-open"
      class="f20 ml10 cursor-pointer" />
    <svg-icon
      v-else
      v-pointerReClick
      @click="onOpen()"
      icon-class="lock-close"
      class="f20 ml10 cursor-pointer" />
    
    <el-dialog
      title="提醒"
      width="500px"
      :visible.sync="dialogVisible"
      :before-close="closeDialogBefore"
      :close-on-click-modal="false" 
      class="vip-msg-box">
      <div class="text-align-center">
        <svg-icon icon-class="mark" class="f36 mb10" />
        <div class="txt">
          <p>{{msg}}</p>
        </div>
      </div>

      <span slot="footer" class="flex-jc-space-between">
        <div class="the-left">
          <!-- <el-checkbox v-model="noRemind">不再提醒</el-checkbox> -->
        </div>
        <div class="the-right">
          <el-button 
            round
            class="w120" 
            @click="onCancel()">
            取消
          </el-button>
          <el-button 
            round 
            type="primary" 
            class="kxx-button w120" 
            @click="onConfirm()">
            确认
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  isFreeTopDetailQuery,
  showTopDetail,
} from '@/api/home.js'
export default {
  name: 'topRankingLock',
  props: {
    row: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      noRemind: false,
      dialogVisible: false,
      msg: '',
    }
  },
  methods: {
    onOpen() {
      this.isFreeTopDetailQuery()
    },
    onConfirm() {
      this.showTopDetail()
      this.dialogVisible = false
    },
    onCancel() {
      this.dialogVisible = false
    },
    closeDialogBefore() {
      this.dialogVisible = false
    },
    async isFreeTopDetailQuery() {
      const { 
        accountId, country,
      } = this.row
      const res =  await isFreeTopDetailQuery({
        accountId, country
      })
      const { 
        success, data = {}
      } = res
      if (success) {
        if (data === '0') {
          this.dialogVisible = true
          this.msg = '查看是否为TOP买家，将要消耗1积分进行查询，请确认是否查询！'
        } else if (data === '1'){
          this.showTopDetail()
          this.dialogVisible = false
        } else {
          this.$set(this.row, 'topRankingUseing', data.topRanking || '')
          this.row = Object.assign(this.row, {
            ...data,
          })
        }
      } else {
        console.log(data)
      }
    },
    async showTopDetail() {
      const { 
        accountId, profileId, country,
      } = this.row
      const res = await showTopDetail({
        accountId, profileId, country,
      })
      const { 
        success, data = {},
      } = res
      if (success) {
        this.$set(this.row, 'topRankingUseing', data.topRanking || '')
        this.row = Object.assign(this.row, {
            ...data,
          })
      }
    }
  },
}
</script>

