<template>
  <el-dialog
    title="绑定手机号"
    :visible.sync="bindPhoneDV"
    width="460px"
    :before-close="closebindPhoneDV"
    class="bind-phone-DV"
    :close-on-click-modal="false"
  >
    <div class="tip">
      为了您的资金安全和更好的提供服务，请绑定您的常用手机号码！
    </div>
    <el-form
      ref="loginFormData"
      :model="loginFormData"
      label-width="22px"
      class="login-form"
    >
      <el-form-item>
        <el-input
          v-model="loginFormData.mobile"
          placeholder="手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="loginFormData.captcha"
          placeholder="图形验证码"
        ></el-input>
        <div class="right-content">
          <img :src="imgCode" @click="tapCutImg" v-pointerReClick/>
        </div>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="loginFormData.validateCode"
          placeholder="验证码"
        ></el-input>
        <div class="right-content">
          <span v-pointerReClick @click="getCode(1)" v-if="!isCount">获取验证码</span>
          <span class="down" v-else>{{ count }}秒后重发</span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handerEditPhone" v-pointerReClick>绑定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { sendMobileCode, firstBindMobile,mobileExist  } from '@/api/user.js'
export default {
  name: '',
  components: {},
  props: {
    bindPhoneDV: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loginFormData: {
        mobile: '',
        captcha: '',
        validateCode: ''
      },
      imgCode: '/api/captcha/get?type=bindMobile',
      count: 0,
      isCount: false,
      codeTimeId: '',
      binding:true,
    }
  },
  computed: {},
  watch: {},
  created() { },
  methods: {
    closebindPhoneDV() {
      this.$emit('bindPhone')
      this.loginFormData = {
        mobile: '',
        captcha: '',
        validateCode: ''
      }
    },
    tapCutImg() {
      this.imgCode = '/api/captcha/get?type=bindMobile' + (+new Date())
    },
    getCode() {
      this.$message.closeAll()
      var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (this.loginFormData.mobile.trim().length == 0) {
        this.$message.error('请输入手机号');
        return
      }
      if (!reg.test(this.loginFormData.mobile)) {
        this.$message.error('手机号格式有误');
        return
      }
      if (this.loginFormData.captcha.trim().length == 0) {
        this.$message.error('请输入图形验证码');
        return
      }
      //手机是否被注册
      mobileExist({mobile:this.loginFormData.mobile}).then(res=>{
        if (res.data) {
          this.$message({
          message: '该手机已经绑定用户',
          type: 'warning'
        });
          this.binding=false
        }else{
          this.binding=true
          this.sendMobileCode()
        }
      })
    },
    sendMobileCode() {
      sendMobileCode({
        mobile: this.loginFormData.mobile,
        type: 'sendMobileCode',
        captcha: this.loginFormData.captcha
      }).then(res => {
        if (!res.success) {
          if (res.errorCode == 'web.args.captcha') {
            clearInterval(this.codeTimeId)
            this.isCount = false
          }
        } else {
          this.isCount = true
          this.count = 59
          this.codeTimeId = setInterval(() => {
            if (this.count == 0) {
              clearInterval(this.codeTimeId)
              this.isCount = false
            } else {
              this.count--
            }
          }, 1000)
          this.tapCutImg()
        }
      })
    },
    handerEditPhone() {
      this.$message.closeAll()
      var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      // 绑定手机号
      if (this.loginFormData.mobile.trim().length == 0) {
        this.$message.error('请输入手机号');
      } else if (!reg.test(this.loginFormData.mobile)) {
        this.$message.error('手机号格式有误');
      } else if (this.loginFormData.captcha.trim().length == 0) {
        this.$message.error('请输入图形验证码');
      } else if (this.loginFormData.validateCode.trim().length == 0) {
        this.$message.error('请输入手机验证码');
      } else {
        this.firstBindMobile()
      }
    },
    firstBindMobile() {
      firstBindMobile(this.loginFormData).then(res => {
        this.$message.closeAll()
        if (res.success) {
          this.$message.success('绑定手机号成功');
          this.$emit('bindPhone', 1)
        }else{
          this.$message.error(res.message);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-dialog__body {
  position: relative;
}
.bind-phone-DV {
  ::v-deep.el-dialog__body {
    padding: 30px 0 !important;
  }
  .tip {
    position: absolute;
    top: 55px;
    left: 20px;
    font-size: 14px;
    color: #ffa200;
  }
  ::v-deep.login-form {
    .el-input__inner {
      width: 400px;
      border: 0;
      border-bottom: 1px solid #edeef3;
    }
    .el-form-item__content {
      position: relative;
    }
    .right-content {
      position: absolute;
      right: 38px;
      top: 0;
      width: 80px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      color: #0668f6;
      .down {
        color: #7a7a7a;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .el-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 48px;
    color: #fff;
    font-size: 16px;
    background: linear-gradient(0deg, #0831ff, #3777ff);
  }
}
</style>
