<template>
  <div class="top-report">
    <div class="container">
      <div class="report-item">
        <div class="title">收到举报</div>
        <div class="svg"></div>
        <div class="num">
          昨日：
          <span>{{ totalReport.todayReportNum }}次</span>
          累计：
          <span>{{ totalReport.totalReportNum }}次</span>
        </div>
        <div class="account-list">
          <div class="list" ref="list">
            <div class="item" v-for="(item, index) in accountList" :key="index">
              <div class="txt">
                账号信息：
                <span class="user-info">{{
                  getUserInfo(item.accountInfo)
                }}</span>
              </div>
              <div class="txt">
                账号类型：
                <span class="account-type">{{ getType(item.reportType) }}</span>
              </div>
              <div class="txt">
                举报理由：
                <span>{{ item.reportReason }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 举报恶意账号 -->
        <div 
          class="right"
          v-if="!isFromFriend"
          @click="handleReport">举报恶意差评/黑名单</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportSummary, exhibitAccount } from '@/api/home.js'
import { mapState } from 'vuex'
export default {
  name: 'topReport',
  components: {},
  props: {},
  data() {
    return {
      totalReport: {
        totalReportNum: 0,
        todayReportNum: 0
      },
      accountList: []
    }
  },
  computed: {
    ...mapState(['isFromFriend']),
  },
  watch: {},
  created() {
    this.reportSummary()
    this.exhibitAccount()
  },
  mounted() {

  },
  methods: {
    handleReport() {
      this.$eventBus.$emit('handleReport')
    },
    async reportSummary() {
      let res = await reportSummary()
      if (res.success) {
        this.totalReport = res.data
      } else {
        this.$message.closeAll()
      }
    },
    async exhibitAccount() {
      let res = await exhibitAccount()
      if (res.success) {
        this.accountList = res.data
        this.$refs['list'].style.animationDuration = `${Math.ceil(this.accountList.length * 2)}s`
      }
    },
    getUserInfo(val) {
      let arr = val.split(':')
      let txt
      switch (arr[0]) {
        case 'profileId':
          txt = `profileId${arr[1]}`
          break;
        case 'amzUsername':
          txt = `亚马逊会员名${arr[1]}`
          break;
        case 'email':
          txt = `邮箱${arr[1]}`
          break;
        case 'facebook':
          txt = `facebook账号${arr[1]}`
          break;
        case 'wechat':
          txt = `微信号${arr[1]}`
          break;
        case 'qq':
          txt = `QQ号${arr[1]}`
          break;
        case 'tiktok':
          txt = `tiktok账号${arr[1]}`
          break;
        case 'twitter':
          txt = `twitter账号${arr[1]}`
          break;
        case 'instagram':
          txt = `instagram${arr[1]}`
          break;
        case 'whatsapp':
          txt = `whatsapp${arr[1]}`
          break;
        case 'other':
          txt = `其他账号${arr[1]}`
          break;
        case 'mobile':
          txt = `电话${arr[1]}`
          break;
        case 'alipay':
          txt = `支付宝账号${arr[1]}`
          break;
        case 'shopName':
          txt = `店铺名称${arr[1]}`
          break;
        case 'brand':
          txt = `卖家品牌${arr[1]}`
          break;
        case 'company':
          txt = `公司名称${arr[1]}`
          break;
        case 'name':
          txt = `姓名${arr[1]}`
          break;
        default:
          txt = `${arr[0]}${arr[1]}`
          break;
      }
      // if (txt.length > 10) {
      //   txt = txt.substring(0, 10)
      // }
      return txt
    },
    getType(val) {
      let txt
      switch (val) {
        case '0':
          txt = '举报测评买家账号'
          break;
        case '1':
          txt = '举报测评中介账号'
          break; case '2':
          txt = '举报不诚信测评卖家'
          break; case '3':
          txt = '其他'
          break;
        default:
          break;
      }
      return txt
    }
  }
}
</script>

<style scoped lang="scss">
.top-report {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .container {
    width: 1200px;
    background: #fefefe;
    box-shadow: 0px 2px 12px 0px #edecf6;
    border-radius: 4px;
  }
  .report-item {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    height: 60px;
    position: relative;
    .title {
      width: 42px;
      font-size: 16px;
      font-weight: bold;
      color: #ff9834;
    }
    .svg {
      width: 36px;
      height: 36px;
      background: url('../../../assets/home/report-svg.svg') no-repeat;
      background-size: 100% 100%;
      margin: 0 16px 0 4px;
    }
    .num {
      padding: 0 12px;
      font-size: 16px;
      font-weight: 400;
      color: #767d8f;
      span {
        font-weight: bold;
        color: #ff9834;
      }
      background: url('../../../assets/home/report-input.png') no-repeat;
      background-size: 100% 100%;
    }
    .account-list {
      white-space: nowrap;
      overflow: hidden;
      height: 20px;
      width: 631px;
      .list {
        animation: move 20s linear infinite normal;
      }
      @keyframes move {
        0% {
          transform: translate3d(0, 0, 0);
        }
        100% {
          transform: translate3d(0, -100%, 0);
        }
      }
      .item {
        display: flex;
      }
      .txt {
        display: flex;
        align-items: center;
        margin-left: 30px;
        font-size: 14px;
        color: #767d8f;
        span {
          color: #24232f;
        }
        .user-info {
          display: inline-block;
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .account-type {
          display: inline-block;
          width: 120px;
        }
      }
    }
    .right {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 175px;
      height: 32px;
      border-radius: 16px;
      background: #FF455B;
      margin-left: 40px;
      font-weight: 400;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
